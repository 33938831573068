import { Box, Modal, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
// import Key from 'assets/icon/key.svg';
// import Like from 'assets/icon/like.svg';
// import Tick from 'assets/icon/tick.svg';
import noProfie from 'assets/images/no_profile.png';
import Logout from 'assets/icon/logout.svg';
import { Button } from '@aws-amplify/ui-react';
import '../style/style.scss';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/commomService';
import { SETUP_ENDPOINT, TABLE_NAME } from 'utils/constant';
import Loader from './Loader';
// import Ribbon from './Ribbon';

const Header = (props: any) => {
  // const { user } = useAuthenticator();
  const accessToken = localStorage.getItem('accessToken');

  const userDetail: any = jwt(accessToken);
  // console.log(userDetail);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [lopen, setLopen] = React.useState(false);
  const [configurationAccessList, setConfigurationAccessList] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(false);
  const handleLogoutOpen = () => setLopen(true);
  const handleLogoutClose = () => setLopen(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { activeIndex } = props;

  const logOut = () => {
    localStorage.clear();
    toast.success('Logout successfully');
    navigate('/');
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 485,
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    p: '28px 24px',
  };

  React.useEffect(() => {
    setLoader(true);
    getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.configurationAccess}&sort_by=user_list`,
    )
      .then((res) => {
        const configurationAccessData = res?.map(
          (accessData: { user_list: string }) => accessData.user_list,
        );
        setConfigurationAccessList(configurationAccessData);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  }, []);

  return (
    <Typography component={'div'}>
      <Loader openLoader={loader} />

      <Typography component={'div'} className='header'>
        {/* <Ribbon /> */}
        {/* <Typography className='logo'></Typography>
        
        <Typography component={'div'} className='d-flex-a' sx={{ marginRight: '90px' }}>
          <Typography
            component={'div'}
            className={activeIndex ? 'active-txt d-flex-ja pointer' : 'd-flex-ja pointer'}
            onClick={() => {
              navigate('/dashboard');
              // setActive(false);
            }}>
            Indexing
          </Typography>
          <Typography
            component={'div'}
            className={!activeIndex ? 'active-txt d-flex-ja pointer' : 'd-flex-ja pointer'}
            onClick={() => {
              navigate('/analytics');
              // setActive(true);
            }}>
            Analytics
          </Typography>
        </Typography> */}
        <Typography component={'div'} className='d-flex-a'>
          <Typography
            component={'div'}
            className={
              activeIndex === 1 ? 'active-txt d-flex-ja pointer' : 'normal-txt d-flex-ja pointer'
            }
            onClick={() => {
              navigate('/dashboard');
              // setActive(false);
            }}>
            Indexing
          </Typography>
          {configurationAccessList?.includes(userDetail?.email) ? (
            <>
              <Typography
                component={'div'}
                className={
                  activeIndex === 2
                    ? 'active-txt d-flex-ja pointer'
                    : 'normal-txt d-flex-ja pointer'
                }
                sx={{ marginLeft: '8px' }}
                onClick={() => {
                  navigate('/analytics');
                  // setActive(true);
                }}>
                Analytics
              </Typography>
              <Typography
                component={'div'}
                className={
                  activeIndex === 3
                    ? 'active-txt d-flex-ja pointer'
                    : 'normal-txt d-flex-ja pointer'
                }
                sx={{ marginLeft: '8px' }}
                onClick={() => {
                  navigate('/configuration');
                }}>
                Configuration
              </Typography>
            </>
          ) : (
            ''
          )}
        </Typography>
        <Typography component={'div'} className='d-flex-a'>
          <Typography component={'div'}>
            {/* <Typography
              component={'div'}
              className='picture'
              sx={{
                backgroundImage: noProfie,
              }}
            /> */}
            <img src={noProfie} alt='no-img' style={{ height: '30px' }} />
          </Typography>
          <>
            <Typography className='pic-name' aria-describedby={id} onClick={handleClick}>
              {/* {userDetail.userName.charAt(0).toUpperCase() + userDetail?.userName.slice(1)} */}
              {userDetail?.userName.charAt(0).toUpperCase() + userDetail?.userName.slice(1)}
            </Typography>
            <Popover
              className='logout-box'
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Typography component={'div'} className='d-flex-a top-border'>
                <Typography component={'div'}>
                  <img src={noProfie} alt='no-img' style={{ height: '30px' }} />
                </Typography>
                <Typography component={'div'}>
                  <Typography className='pic-name' aria-describedby={id} onClick={handleClick}>
                    {/* {user?.username.charAt(0).toUpperCase() + user?.username.slice(1)} */}
                    {userDetail?.userName.charAt(0).toUpperCase() + userDetail?.userName.slice(1)}
                  </Typography>
                  <Typography className='id-info'>
                    {/* {user?.attributes?.email} */}

                    {userDetail?.email}
                  </Typography>
                </Typography>
              </Typography>
              {/* <Typography component={'div'} className=' top-border'>
                <Typography component={'div'} className='d-flex-a mb-24 disableField'>
                  <img src={Key} alt='key' />
                  <Typography className='medium-txt pl-11 '>Reset Password</Typography>
                </Typography>
                <Typography component={'div'} className='d-flex-a mb-24 disableField'>
                  <img src={Like} alt='like' />
                  <Typography className='medium-txt pl-11 '>Submit Feedbacks</Typography>
                </Typography>
                <Typography component={'div'} className='d-flex-a disableField'>
                  <img src={Tick} alt='tick' />
                  <Typography className='medium-txt pl-11 '>Whats New?</Typography>
                </Typography>
              </Typography> */}
              <Typography
                component={'div'}
                className='d-flex-a d-flex-sb p-20'
                sx={{ background: '#FCFDFF' }}>
                <Typography
                  component={'div'}
                  className='d-flex-a pointer'
                  onClick={handleLogoutOpen}>
                  <img src={Logout} alt='Logout' />
                  <Typography className='red-txt pl-11'>Logout</Typography>
                </Typography>
                <Modal
                  open={lopen}
                  onClose={handleLogoutClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'>
                  <Box sx={style}>
                    <Typography className='logout-title '>Logout</Typography>
                    <Typography className='logout-dis'>Are you sure you want to logout?</Typography>
                    <Typography component={'div'} className='d-flex-a flex-end'>
                      <Button className='l-cancel-btn' onClick={handleLogoutClose}>
                        Cancel
                      </Button>
                      <Button className='logout-btn ' onClick={() => logOut()}>
                        Logout
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
                <Typography className='version-text'>Version 1.0</Typography>
              </Typography>
            </Popover>
          </>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default Header;
