/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createItechIndexing_primary = /* GraphQL */ `mutation CreateItechIndexing_primary(
  $input: CreateItechIndexing_primaryInput!
) {
  createItechIndexing_primary(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateItechIndexing_primaryMutationVariables,
  APITypes.CreateItechIndexing_primaryMutation
>;
export const updateItechIndexing_primary = /* GraphQL */ `mutation UpdateItechIndexing_primary(
  $input: UpdateItechIndexing_primaryInput!
) {
  updateItechIndexing_primary(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateItechIndexing_primaryMutationVariables,
  APITypes.UpdateItechIndexing_primaryMutation
>;
export const deleteItechIndexing_primary = /* GraphQL */ `mutation DeleteItechIndexing_primary(
  $input: DeleteItechIndexing_primaryInput!
) {
  deleteItechIndexing_primary(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteItechIndexing_primaryMutationVariables,
  APITypes.DeleteItechIndexing_primaryMutation
>;
export const createIndexingDashboardPriority = /* GraphQL */ `mutation CreateIndexingDashboardPriority(
  $input: CreateIndexingDashboardPriorityInput!
) {
  createIndexingDashboardPriority(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndexingDashboardPriorityMutationVariables,
  APITypes.CreateIndexingDashboardPriorityMutation
>;
export const updateIndexingDashboardPriority = /* GraphQL */ `mutation UpdateIndexingDashboardPriority(
  $input: UpdateIndexingDashboardPriorityInput!
) {
  updateIndexingDashboardPriority(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndexingDashboardPriorityMutationVariables,
  APITypes.UpdateIndexingDashboardPriorityMutation
>;
export const deleteIndexingDashboardPriority = /* GraphQL */ `mutation DeleteIndexingDashboardPriority(
  $input: DeleteIndexingDashboardPriorityInput!
) {
  deleteIndexingDashboardPriority(input: $input) {
    signed_email_id
    created
    attachment_number
    attachment_size
    batched_result
    batched_status
    clarify_requested_by
    client_code
    date_reconcile
    description
    display_task
    email
    email_body_pages
    email_raw_file_path
    file_log
    file_source
    from
    iscompleted_by
    isrejected_by
    location
    message_id
    ml_result
    ml_status
    pages
    priority
    scac
    source
    task_info
    task_lock
    task_lock_by
    task_status
    total_attachment
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndexingDashboardPriorityMutationVariables,
  APITypes.DeleteIndexingDashboardPriorityMutation
>;
export const createLogisticsClientInformation = /* GraphQL */ `mutation CreateLogisticsClientInformation(
  $input: CreateLogisticsClientInformationInput!
) {
  createLogisticsClientInformation(input: $input) {
    client_code
    account_name
    client_location
    processing_site_code
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogisticsClientInformationMutationVariables,
  APITypes.CreateLogisticsClientInformationMutation
>;
export const updateLogisticsClientInformation = /* GraphQL */ `mutation UpdateLogisticsClientInformation(
  $input: UpdateLogisticsClientInformationInput!
) {
  updateLogisticsClientInformation(input: $input) {
    client_code
    account_name
    client_location
    processing_site_code
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLogisticsClientInformationMutationVariables,
  APITypes.UpdateLogisticsClientInformationMutation
>;
export const deleteLogisticsClientInformation = /* GraphQL */ `mutation DeleteLogisticsClientInformation(
  $input: DeleteLogisticsClientInformationInput!
) {
  deleteLogisticsClientInformation(input: $input) {
    client_code
    account_name
    client_location
    processing_site_code
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLogisticsClientInformationMutationVariables,
  APITypes.DeleteLogisticsClientInformationMutation
>;
export const createScacCode = /* GraphQL */ `mutation CreateScacCode($input: CreateScacCodeInput!) {
  createScacCode(input: $input) {
    unique_id
    scac
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScacCodeMutationVariables,
  APITypes.CreateScacCodeMutation
>;
export const updateScacCode = /* GraphQL */ `mutation UpdateScacCode($input: UpdateScacCodeInput!) {
  updateScacCode(input: $input) {
    unique_id
    scac
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScacCodeMutationVariables,
  APITypes.UpdateScacCodeMutation
>;
export const deleteScacCode = /* GraphQL */ `mutation DeleteScacCode($input: DeleteScacCodeInput!) {
  deleteScacCode(input: $input) {
    unique_id
    scac
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScacCodeMutationVariables,
  APITypes.DeleteScacCodeMutation
>;
export const createScacCarrierInformation = /* GraphQL */ `mutation CreateScacCarrierInformation(
  $input: CreateScacCarrierInformationInput!
) {
  createScacCarrierInformation(input: $input) {
    SCAC
    carrier_name
    model_trained
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScacCarrierInformationMutationVariables,
  APITypes.CreateScacCarrierInformationMutation
>;
export const updateScacCarrierInformation = /* GraphQL */ `mutation UpdateScacCarrierInformation(
  $input: UpdateScacCarrierInformationInput!
) {
  updateScacCarrierInformation(input: $input) {
    SCAC
    carrier_name
    model_trained
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScacCarrierInformationMutationVariables,
  APITypes.UpdateScacCarrierInformationMutation
>;
export const deleteScacCarrierInformation = /* GraphQL */ `mutation DeleteScacCarrierInformation(
  $input: DeleteScacCarrierInformationInput!
) {
  deleteScacCarrierInformation(input: $input) {
    SCAC
    carrier_name
    model_trained
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScacCarrierInformationMutationVariables,
  APITypes.DeleteScacCarrierInformationMutation
>;
