/* eslint-disable @typescript-eslint/restrict-template-expressions */
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Worker } from '@react-pdf-viewer/core';
import React from 'react';

import PDFViewer from './PDFViewer';

export const CustomPDFViewer: React.FC<any> = ({ fileUrl, updateSearchPage, jumpIndex }) => {
  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
      <PDFViewer fileUrl={fileUrl} jumpIndex={jumpIndex} updateSearchPage={updateSearchPage} />;
    </Worker>
  );
};

export default React.memo(CustomPDFViewer);
