import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// import VectorImg from '../../assets/icon/Vector.svg';
// import SearchIcon from '@mui/icons-material/Search';
// import ForwardIcon from '@mui/icons-material/Forward';
// import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Header from 'components/Header';
import Loader from 'components/Loader';

// import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import axios from 'axios';
import CustomPDFViewer from 'components/CustomPDFViewer';

const CompleteView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(true);
  const [jumpIndex, setJumpindex] = useState<number>(0);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [priority, setPriority] = useState<number>();
  const location = useLocation();
  const { splitInfo, splitIndex, fileSource, pages } = location.state;
  const [pdfUrl, setPdfUrl] = React.useState('');

  const navToPreviousPage = () => {
    navigate(`/dashboard?tabName=${params.taskStatus}`);
  };

  const uriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri?.match(pattern);

    if (match) {
      const bucket = match[1];
      const key = encodeURIComponent(match[2]);
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/${key}`;
    } else {
      return null;
    }
  };
  const handleUserKeyPress = React.useCallback((event) => {
    const { key } = event;
    if (key === 'Escape') {
      navToPreviousPage();
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const updateSearchPage = (pageNumber) => {
    if (pageNumber) {
      setSearchPage(pageNumber);
    }
  };
  useEffect(() => {
    setPriority(Number(location.state.priority));
    const url = uriTourl(fileSource?.email_meta_data_path);
    axios.get(url, { responseType: 'blob' }).then((res: any) => {
      if (res.data) {
        setLoader(false);
        setPdfUrl(URL?.createObjectURL(res?.data));
      }
    });
  }, []);

  // useEffect(() => {
  //   setPage(searchPage);
  // }, [searchPage]);

  useEffect(() => {
    if (searchPage) {
      document?.getElementById(`pdfInvoiceTrack${searchPage - 2}`)?.scrollIntoView();
    }
  }, [searchPage]);

  return (
    <Typography component={'div'}>
      {/* <Header activeIndex={1} /> */}
      <Typography component={'div'} className='innerbody'>
        <Typography component={'div'} className='d-flex-a mb-16'>
          <ArrowBackIcon
            className='mr-15'
            onClick={() => navToPreviousPage()}
            sx={{ cursor: 'pointer' }}
          />
          <Typography className='back-text'>Index the Document</Typography>
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item md={8} sm={12}>
            {!loader && pdfUrl ? (
              <CustomPDFViewer
                updateSearchPage={updateSearchPage}
                fileUrl={pdfUrl}
                jumpIndex={jumpIndex}
              />
            ) : (
              <Loader openLoader={loader} />
            )}
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography component={'div'} className='pdf-edit-box'>
              <Typography component={'div'} className='edit-head d-flex-a d-flex-sb'>
                <Typography className='medium-title'>Total {pages} Pages</Typography>
                <Typography component={'div'} className='d-flex-a border-left'>
                  <Typography className='medium-title mr-15'>Priority</Typography>
                  <Box>
                    <FormControl fullWidth>
                      <Select
                        disabled
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        defaultValue={Number(location.state.priority)}
                        IconComponent={() => <ExpandMoreIcon className='d-arrow' />}>
                        <MenuItem value={Number(priority)}>{Number(priority) || 0}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Typography>
              </Typography>
              <Typography component={'div'} className='edit-body'>
                <Typography component={'div'} className='inner-scroll'>
                  {splitInfo.map((data: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {index === 0 ? (
                          <Grid
                            container
                            key={index}
                            id={`pdfInvoiceTrack${index + 1}`}
                            columnSpacing={1}>
                            <Grid item md={1.5} className='d-flex-a flex-end'>
                              <Typography component={'div'}>
                                <Typography
                                  className={
                                    index + 1 === searchPage
                                      ? 'count-box1 d-flex-ja pointer'
                                      : 'count-box d-flex-ja pointer'
                                  }
                                  onClick={() => {
                                    setJumpindex(index + 1);
                                    setSearchPage(index + 1);
                                  }}>
                                  {index + 1}
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item md={10.5}>
                              <Typography className='raw-box'>RAW Email</Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            columnSpacing={1}
                            sx={{ marginTop: '15px' }}
                            key={index}
                            id={`pdfInvoiceTrack${index + 1}`}>
                            <Grid item md={1.5} className='d-flex-a flex-end'>
                              <Typography component={'div'}>
                                <Typography
                                  className={
                                    index + 1 === searchPage
                                      ? 'count-box1 d-flex-ja pointer'
                                      : 'count-box d-flex-ja pointer'
                                  }
                                  onClick={() => {
                                    setJumpindex(index + 1);
                                    setSearchPage(index + 1);
                                  }}>
                                  {index + 1}
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item md={3.5}>
                              <TextField
                                disabled
                                variant='outlined'
                                placeholder='Client Code'
                                defaultValue={data?.client_code}
                              />
                            </Grid>
                            <Grid item md={3.5}>
                              <TextField
                                disabled
                                sx={{ width: '100%' }}
                                placeholder='SCAC Code'
                                defaultValue={data.sac_code}
                                variant='outlined'

                                // value={value}
                              />
                            </Grid>
                            <Grid item md={3.3}>
                              <FormControl fullWidth>
                                <Select
                                  disabled
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  defaultValue={data.isInvoice ? 1 : 0}
                                  // IconComponent={() => <ExpandMoreIcon className='d-arrow' />}
                                >
                                  <MenuItem value={1} key={index}>
                                    {'INVOICE'}
                                  </MenuItem>
                                  <MenuItem value={0} key={index}>
                                    {'MISC'}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                        {splitIndex.indexOf(index) !== -1 && splitInfo.length !== index + 1 ? (
                          <Typography component={'div'} className='d-flex-ja'>
                            <Divider
                              sx={{ width: '95%', marginTop: '10px', border: '1px solid #ff8383' }}
                            />
                          </Typography>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Typography>
                <Typography component={'div'} className='d-flex-a flex-end'>
                  <Typography>
                    <Button className='save-btn' onClick={() => navToPreviousPage()}>
                      Close
                    </Button>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Typography>
  );
};

export default CompleteView;
