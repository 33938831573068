/* eslint-disable @typescript-eslint/restrict-template-expressions */
// Import the styles
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { ThumbnailIcon } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
// Import styles of default layout plugin
import { Grid, Tooltip, Typography } from '@mui/material';
import {
  Viewer,
  RotateDirection,
  CharacterMap,
  // RenderPageProps,
  SetRenderRange,
  VisiblePagesRange,
} from '@react-pdf-viewer/core';
// default layout plugin
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// import Loader from 'components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
interface pdfProps {
  fileUrl: any;
  jumpIndex?: any;
  updateSearchPage?: any;
}

// const CustomPageRender: React.FC<{
//   renderPageProps: RenderPageProps;
// }> = ({ renderPageProps }) => {
//   console.log(renderPageProps);
//   React.useEffect(() => {
//     if (renderPageProps.canvasLayerRendered && renderPageProps.textLayerRendered) {
//       renderPageProps.markRendered(renderPageProps.pageIndex);
//     }
//   }, [renderPageProps.canvasLayerRendered, renderPageProps.textLayerRendered]);

//   return (
//     <>
//       {/* Use the canvas and/or text layers */}
//       {renderPageProps.canvasLayer.children}
//       {renderPageProps.textLayer.children}

//       {/* Your custom components on page ... */}
//     </>
//   );
// };

const PDFViewer: React.FC<any> = (pdfProps: pdfProps) => {
  const viewerRef = useRef<any>(null);
  const { fileUrl, jumpIndex, updateSearchPage } = pdfProps;
  const [sidePanalOpen, setSidePanalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const rotatePluginInstance = rotatePlugin();
  const { RotatePage } = rotatePluginInstance;
  const { jumpToPage } = pageNavigationPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderToolBar = (props) => {
    const {
      CurrentPageInput,
      Download,
      GoToNextPage,
      GoToPreviousPage,
      NumberOfPages,
      Print,
      Zoom,
      ZoomIn,
      ZoomOut,
    } = props;

    return (
      <>
        <Grid md={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid md={3} sx={{ display: 'flex', marginLeft: '20px' }}>
            <MenuOpenIcon className='pointer' onClick={() => setSidePanalOpen(!sidePanalOpen)} />
          </Grid>

          <Grid md={2}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </Typography>
          </Grid>

          <Grid md={5}>
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <RotatePage>
                {(props) => (
                  <Tooltip title={'Rotate left'} arrow>
                    <RotateLeftIcon
                      sx={{
                        color: 'rgb(0 0 0 / 50%) !important',
                        '&:hover': {
                          backgroundColor: 'rgb(0 0 0 / 10%)',
                        },
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                      onClick={() => props.onRotatePage(currentPage, RotateDirection.Backward)}
                    />
                  </Tooltip>
                )}
              </RotatePage>
              <RotatePage>
                {(props) => (
                  <Tooltip title={'Rotate right'}>
                    <RotateRightIcon
                      sx={{
                        color: 'rgb(0 0 0 / 50%) !important',
                        '&:hover': {
                          backgroundColor: 'rgb(0 0 0 / 10%)',
                        },
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                      onClick={() => props.onRotatePage(currentPage, RotateDirection.Forward)}
                    />
                  </Tooltip>
                )}
              </RotatePage>
              <GoToPreviousPage />
              <GoToNextPage />
              <CurrentPageInput />
              / <NumberOfPages />
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <Download />
              <Print />
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const characterMap: CharacterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: 'https://unpkg.com/pdfjs-dist@2.6.347/cmaps/',
  };

  const setRenderRange: SetRenderRange = React.useCallback(
    (visiblePagesRange: VisiblePagesRange) => {
      return {
        startPage: visiblePagesRange.endPage <= 20 ? 0 : visiblePagesRange.startPage - 5,
        endPage:
          visiblePagesRange.startPage <= 20
            ? Math.max(20, visiblePagesRange.endPage + 5)
            : visiblePagesRange.endPage + 5,
      };
    },
    [],
  );

  const handlePageChange = React.useCallback((data) => {
    setCurrentPage(data?.currentPage);
    updateSearchPage(data?.currentPage + 1);
  }, []);

  useEffect(() => {
    jumpToPage(jumpIndex - 1);
  }, [jumpIndex]);

  return (
    <Typography
      component={'div'}
      className='rpv-core__viewer'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03) !important',
      }}>
      <Typography
        component={'div'}
        sx={{
          alignItems: 'center',
          backgroundColor: '#e5ebfa',
          display: 'flex',
          padding: '4px',
          borderRadius: '12px 12px 0px 0px',
        }}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            return renderToolBar(props);
          }}
        </Toolbar>
      </Typography>
      <div
        className='rpv-core__viewer'
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '100%',
        }}>
        <div
          style={{
            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
            overflow: 'auto',
            width: '20%',
            display: !sidePanalOpen ? 'none' : '',
          }}>
          <Thumbnails />
        </div>
        <Typography
          component={'div'}
          sx={{
            flex: 1,
            overflow: 'hidden',
            backgroundColor: '#e6e6e8 !important',
          }}>
          <Viewer
            fileUrl={fileUrl}
            defaultScale={1.2}
            ref={viewerRef}
            onPageChange={(e) => handlePageChange(e)}
            plugins={[
              toolbarPluginInstance,
              thumbnailPluginInstance,
              pageNavigationPluginInstance,
              rotatePluginInstance,
            ]}
            enableSmoothScroll={false}
            setRenderRange={setRenderRange}
            characterMap={characterMap}
            // renderPage={(props) => <CustomPageRender renderPageProps={props} />}
            // renderLoader={(percentages: number) => <CircularProgress />}
          />
        </Typography>
      </div>
    </Typography>
  );
};

export default PDFViewer;
