// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';

export const SETUP_ENDPOINT = {
  list: '/list',
  insert: '/insert',
  update: '/update',
  delete: '/delete',
  count: '/count',
  clone: '/clone',
  search: '/search',
  clarifySearch: '/search_clarify',
};

export const TABLE_NAME = {
  pending: 'production_inprogress',
  completed: 'production_completed',
  priority: 'production_priority',
  scac: 'scac_carrier_information',
  cilent: 'logistics_client_information',
  clone: 'production_clone',
  reason: 'reason_clarification',
  iclarify: 'iclarify',
  error: 'd2l_error_log',
  scacEmail: 'scac_code',
  clientKey: 'client_code_keyword_information',
  configurationAccess: 'configuration_access',
  duplicate: 'production_duplicate',
  taskLockRestrictionUsers: 'indexing_lock_access',
};
