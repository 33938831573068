import { Box, Grid, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import VectorImg from '../../assets/icon/Vector.svg';
import SearchIcon from '@mui/icons-material/Search';
import ForwardIcon from '@mui/icons-material/Forward';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loader from 'components/Loader';

import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';

const ImageViewer = (props: any) => {
  const { splitInfo, currentPage, setSearchPage } = props;
  const params = useParams();
  const navigate = useNavigate();
  const ref = useRef();
  const [total, setTotal] = useState<number>(1);
  const [pdfSideMenu, setPdfSideMenu] = useState<boolean>(true);
  const [urlConvert, setUrlConvert] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  // const [currentPage, setSearchPage] = useState<number>(currentPage);
  const [rotate, setRotate] = useState<number>(90);
  const [searchPage, setPdfSearchPage] = useState<number>(1);

  const uriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = match[2];
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/${key}`;
    } else {
      return null;
    }
  };

  const zoomin = () => {
    const GFG = document.getElementById(`imageTrack-${currentPage}`);
    const currWidth = GFG.clientWidth;
    GFG.style.width = currWidth + 100 + 'px';
  };

  const zoomout = () => {
    const GFG = document.getElementById(`imageTrack-${currentPage}`);
    const currWidth = GFG.clientWidth;
    GFG.style.width = currWidth - 100 + 'px';
  };

  const navToPreviousPage = () => {
    navigate(`/dashboard?tabName=${params.taskStatus}`);
  };

  const handleUserKeyPress = React.useCallback((event) => {
    const { key } = event;
    if (key === 'Escape') {
      navToPreviousPage();
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const rotateFun = () => {
    if (rotate < 360) {
      setRotate(rotate + 90);
      document.getElementById(`imageTrack-${currentPage}`).style.transform = `rotate(${rotate}deg)`;
      document.getElementById(`imageTrack-${currentPage}`).style.marginBottom = '80px';
      document.getElementById(`imageTrack-${currentPage}`).style.marginTop = '80px';
    } else {
      setRotate(90);
      document.getElementById(`imageTrack-${currentPage}`).style.transform = `rotate(${rotate}deg)`;
    }
  };
  const divTrack = () => {
    const observer: any = new IntersectionObserver(
      (entries) => {
        let ele: any = entries.filter((entry) => entry.isIntersecting);
        if (ele.length > 0) {
          ele = ele[0].target;
          setSearchPage(Number(ele.id.split('-')[1]));
          setPdfSearchPage(Number(ele.id.split('-')[1]));
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );
    observer.disconnect();

    document?.querySelectorAll('.test [id^=imageTrack-]')?.forEach((ele) => observer?.observe(ele));
  };
  useEffect(() => {
    setUrlConvert([]);
  }, []);
  useEffect(() => {
    divTrack();
  }, [urlConvert, currentPage]);
  useEffect(() => {
    splitInfo?.forEach((convertedUrl) => {
      const urlPath = uriTourl(convertedUrl.img_path);
      setUrlConvert((pre: any) => [...pre, urlPath]);
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSearchPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setImages(splitInfo);
    setTotal(images?.length);
    setLoader(false);
  }, [images]);

  useEffect(() => {
    if (currentPage) {
      document?.getElementById(`imageTrack-${currentPage}`)?.scrollIntoView();
      document?.getElementById(`imageTracks${currentPage}`)?.scrollIntoView();
      document?.getElementById(`pdfInvoiceTrack${currentPage - 2}`)?.scrollIntoView();
    }
  }, [currentPage]);

  return (
    <Typography component={'div'}>
      <Typography component={'div'} className='innerbody' sx={{ padding: '10px !important' }}>
        <Grid container columnSpacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item md={10} sm={12}>
            <Grid
              md={12}
              sm={12}
              sx={{ backgroundColor: '#4B546A !important', borderRadius: '8px 8px 0px 0px' }}>
              <Typography sx={{ display: 'flex', justifyContent: 'center', height: '40px' }}>
                <Grid md={2} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title='side menu open / close'>
                    <img
                      src={VectorImg}
                      style={{ marginLeft: '21px', cursor: 'pointer' }}
                      onClick={() => setPdfSideMenu(!pdfSideMenu)}
                    />
                  </Tooltip>
                </Grid>
                <Grid md={4} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title='Search'>
                    <SearchIcon className='mr-7 pointer' sx={{ color: 'white' }} />
                  </Tooltip>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      marginLeft: '3px',
                      height: '23px',
                      marginTop: '10px',
                      backgroundColor: 'white',
                      color: 'white !important',
                    }}
                  />
                  <Tooltip title='Page Down'>
                    <ForwardIcon
                      className='ml-9 pointer'
                      onClick={() =>
                        // Number(currentPage) - 1 < 1 ? setSearchPage(total) : setSearchPage(Number(currentPage) - 1)
                        Number(currentPage) - 1 < 1
                          ? setSearchPage(1)
                          : setSearchPage(Number(currentPage) - 1)
                      }
                      sx={{ fill: 'white', rotate: '90deg !important' }}
                    />
                  </Tooltip>
                  <Divider
                    orientation='vertical'
                    flexItem
                    className='mr-2 ml-9'
                    style={{
                      height: '23px',
                      marginTop: '10px',
                      backgroundColor: 'white',
                      color: 'white !important',
                    }}
                  />
                  <Tooltip title='Page Up' className='mr-4 pointer'>
                    <ForwardIcon
                      className='ml-9'
                      onClick={() =>
                        // Number(currentPage) + 1 <= total ? setSearchPage(Number(currentPage) + 1) : setSearchPage(1)
                        Number(currentPage) + 1 <= total && setSearchPage(Number(currentPage) + 1)
                      }
                      sx={{ color: 'white', rotate: '270deg !important', marginBottom: '5px' }}
                    />
                  </Tooltip>
                  <TextField
                    className='mr-2 pdfInput'
                    value={searchPage}
                    onChange={(e: any) => {
                      Number(e.target.value) >= 0 &&
                        Number(e.target.value) <= total &&
                        setPdfSearchPage(Number(e.target.value));
                    }}
                    sx={{
                      width: '12%',
                      minHeight: '30% !important',
                      padding: '0px !important',
                      backgroundColor: '#717B94',
                      color: 'white',
                    }}
                  />
                  <span className='ml-9' style={{ color: 'white', marginLeft: '3px' }}>
                    of
                  </span>
                  <span className='ml-9' style={{ color: 'white', marginLeft: '3px' }}>
                    {total}
                  </span>
                </Grid>
                <Grid md={4} sm={12} className='d-flex-a'>
                  <Tooltip title='Rotate'>
                    <Rotate90DegreesCwIcon
                      className='ml-1 pointer'
                      sx={{ color: 'white' }}
                      onClick={() => rotateFun()}
                    />
                  </Tooltip>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      height: '23px',
                      marginTop: '10px',
                      backgroundColor: 'white',
                      color: 'white !important',
                    }}
                  />
                  <Tooltip title='Zoom Out'>
                    <ZoomOutIcon
                      className='mr-1 pointer'
                      sx={{ color: 'white' }}
                      onClick={() => zoomout()}
                    />
                  </Tooltip>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{
                      marginLeft: '7px',
                      height: '23px',
                      marginTop: '10px',
                      backgroundColor: 'white',
                      color: 'white !important',
                    }}
                  />
                  <Tooltip title='Zoom In'>
                    <ZoomInIcon
                      className='ml-9 pointer'
                      sx={{ color: 'white' }}
                      onClick={() => zoomin()}
                    />
                  </Tooltip>
                </Grid>
                <Grid md={2} sm={12} className='d-flex-ja'>
                  <Tooltip title='more'>
                    <MoreVertIcon sx={{ fill: 'white' }} className='pointer' />
                  </Tooltip>
                </Grid>
              </Typography>
            </Grid>
            <Typography component={'div'} className='pdf-box' sx={{ backgroundColor: 'gainsboro' }}>
              <Grid md={12} sm={12} sx={{ display: 'flex ' }}>
                {!pdfSideMenu && (
                  <Grid md={2} sm={12} sx={{ maxHeight: '90vh', width: '100%', overflowY: 'auto' }}>
                    {urlConvert?.map((data, index) => (
                      <>
                        <Box
                          key={index}
                          id={`imageTracks${index + 1}`}
                          sx={{
                            display: 'flex',
                            '& > :not(style)': {
                              m: 2,
                              width: 80,
                              height: 100,
                            },
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}>
                          <img
                            src={data}
                            alt={'check'}
                            className='pointer'
                            onClick={() => setPdfSearchPage(index + 1)}
                            style={{
                              border: index + 1 === currentPage && 'solid 4px #92b6ef',
                              boxShadow: '0 0 8px 0px grey',
                            }}
                          />
                        </Box>

                        <Typography className='d-flex-ja' sx={{ fontSize: '12px' }}>
                          {index + 1}
                        </Typography>
                      </>
                    ))}
                  </Grid>
                )}
                <Grid
                  md={pdfSideMenu === true ? 12 : 10}
                  sm={12}
                  sx={{ maxHeight: '90vh', overflow: 'auto' }}
                  className='test'>
                  <Typography id={'pdfZoom'}>
                    {!urlConvert.length ? (
                      <Loader openLoader={loader} />
                    ) : (
                      urlConvert?.map((data, index) => (
                        <Typography key={index} sx={{ overflow: 'auto' }}>
                          <Box
                            ref={ref}
                            id={`imageTrack-${index + 1}`}
                            sx={{
                              display: 'flex',
                              '& > :not(style)': {
                                m: 2,
                              },
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <img
                              src={data}
                              alt={'no image'}
                              style={{
                                boxShadow: '0 0 20px 0px grey',
                                height: '100%',
                                width: '85%',
                                overflow: 'auto',
                              }}
                            />
                          </Box>
                        </Typography>
                      ))
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Typography>
  );
};

export default ImageViewer;
