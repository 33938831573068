/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormHelperText } from '@mui/material';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
// import Select from 'react-dropdown-select';
import Select, { components, NoticeProps } from 'react-select';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: any;
  error?: any;
  options?: any;
  labelKey?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  disabled?: boolean;
  selectedValue?: string;
  autoFocus?: boolean;
  searchable?: boolean;
  onClose?: any;
  tabIndex?: number;
  onFocus?: (src: any) => void;
  sorting?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  onInputChange?: (src: any) => void;
  inputValue?: string;
};

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p>No Options Available</p>
    </components.NoOptionsMessage>
  );
};

const CustomSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value = '',
    error,
    options,
    labelKey = 'label',
    valueKey = 'value',
    id,
    name,
    ref,
    disabled,
    autoFocus = false,
    searchable = true,
    isClearable = true,
    onFocus,
    tabIndex,
    sorting,
    isMulti = false,
    closeMenuOnSelect = true,
    onInputChange,
    inputValue,
  } = props;
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      sorting
        ? options
            .filter((ch) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            .map((e) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : '',
              [labelKey]: e[labelKey] ? String(e[labelKey]) : '',
              value: e[valueKey],
            }))
            .sort((a, b) =>
              String(a[labelKey]).toLowerCase() > String(b[labelKey]).toLowerCase() ? 1 : -1,
            )
        : options
            .filter((ch) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            .map((e) => ({
              ...e,
              label: e[labelKey] ? String(e[labelKey]) : '',
              [labelKey]: e[labelKey] ? String(e[labelKey]) : '',
              value: e[valueKey],
            })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <>
      <Select
        autoFocus={autoFocus}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        isSearchable={searchable}
        id={id}
        ref={ref}
        isMulti={isMulti}
        classNames={{
          control: (state) => (state.isFocused ? 'border-red-600' : 'border-grey-300'),
        }}
        onFocus={onFocus && onFocus}
        styles={customStyles}
        isDisabled={disabled}
        name={name}
        tabIndex={tabIndex}
        onInputChange={(e) => onInputChange && onInputChange(e)}
        inputValue={inputValue}
        placeholder={placeHolder}
        components={{ NoOptionsMessage }}
        {...(value !== '' || isMulti
          ? {
              value: isMulti
                ? value && Array.isArray(value) && value.length
                  ? option.filter((el) => value.includes(el[valueKey]))
                  : null
                : option &&
                  option.length &&
                  value &&
                  option.find((el) => String(el[valueKey]) === String(value))
                ? option.find((el) => String(el[valueKey]) === String(value))
                : null,
            }
          : {})}
        options={!_.isEmpty(option) && option.length ? option : []}
        onChange={(e, ac) => {
          if (isMulti) {
            onChange &&
              onChange(
                e
                  ? {
                      target: {
                        value: e && Array.isArray(e) && e.length ? e.map((e) => e[valueKey]) : null,
                      },
                    }
                  : { target: { value: '' } },
              );
            return;
          }
          onChange && onChange(e ? { target: { value: e['value'] } } : { target: { value: '' } });
        }}
      />
      {error && (
        <FormHelperText style={{ color: '#ff0000', marginLeft: '0px' }}>{error}</FormHelperText>
      )}
    </>
  );
};
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '5px !important',
    fontSize: '14px !important',
    minHeight: '35px !important',
    cursor: 'pointer',
  }),
  option: (styles, state) => ({
    ...styles,
    cursor: 'pointer',
  }),
  indicatorContainer: (provided, state) => ({
    padding: '6px !important',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '10000',
    cursor: 'pointer',
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '200px !important',
    cursor: 'pointer !important',
  }),
  // multiValue: (provided, state) => ({
  //   ...provided,
  //   background: ' #0096fb',
  //   borderRadius: ' 11px',
  //   color: ' #fff !important',
  //   div: {
  //     ':hover': {
  //       ':Svg': {
  //         backgroundColor: '0096fb',
  //       },
  //       color: '#fff',
  //     },
  //     backgroundColor: 'none',
  //     color: '#fff',
  //   },
  //   fontSize: ' 13px',
  //   marginBottom: ' 5px',
  //   marginRight: '5px',
  //   padding: '4px 10px',
  // }),
  placeholder: (provided) => ({
    ...provided,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: '14px !important',
  }),
};
export default CustomSelect;
