/* eslint-disable camelcase */
import Header from 'components/Header';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { scacCodeSchema } from 'utils/ValidatorSchema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/customInput';
import CustomSelect from 'components/customSelect';
import axios from 'axios';
import { SETUP_ENDPOINT, TABLE_NAME } from 'utils/constant';
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/commomService';
import _ from 'lodash';

const ConFiguration = () => {
  const requiredTable = [
    {
      name: 'Scac',
      id: 'scac',
    },
    {
      name: 'Client Code',
      id: 'clientCode',
    },
    {
      name: 'Scac & Email',
      id: 'scac&Email',
    },
    {
      name: 'Client Code & Key',
      id: 'ClientCode&Key',
    },
  ];

  const requiredAction = [
    {
      name: 'Insert',
      id: 'insert',
    },

    {
      name: 'Inactive',
      id: 'inactive',
    },
  ];

  const modelTrained = [
    {
      name: 'Yes',
      id: 'yes',
    },
    {
      name: 'No',
      id: 'no',
    },
  ];
  const clientLoction = [
    {
      name: 'US',
      id: 'US',
    },
    {
      name: 'EU',
      id: 'EU',
    },
  ];
  const clientType = [
    {
      name: 'USD',
      id: 'USD',
    },
    {
      name: 'CAD',
      id: 'CAD',
    },
  ];
  const siteCode = [
    {
      name: 'VPM',
      id: 'VPM',
    },
    {
      name: 'TEN',
      id: 'TEN',
    },
    {
      name: 'CHN',
      id: 'CHN',
    },
  ];
  const siteCode1 = [
    {
      name: 'CS10001',
      id: 'CS10001',
    },
    {
      name: 'CS10002',
      id: 'CS10002',
    },
    {
      name: 'CS10006',
      id: 'CS10006',
    },
  ];
  const priority = [
    {
      name: '5',
      id: '5',
    },
    {
      name: '2',
      id: '2',
    },
  ];

  const [loader, setLoader] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [scacData, setScacData] = React.useState<any>([]);
  const [scacData1, setScacData1] = React.useState<any>([]);
  const [scacFilter, setScacFilter] = React.useState('');
  const [scacFilter1, setScacFilter1] = React.useState('');
  const [clientFilter, setClientFilter] = React.useState('');
  const [clientEmailFilter, setClientEmailFilter] = React.useState('');
  const [clientEmailData, setClientEmailData] = React.useState([]);
  const [logisticsData, setLogisticsData] = React.useState<any>([]);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const {
    control: control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    criteriaMode: 'all',
    defaultValues: {
      carrierName: '',
      carrierid: '',
      scac: '',
      modelTrained: '',
      deleteScac: '',
      reason: '',
      reasonAction: '',
      clientCode: '',
      accountNumber: '',
      clientLocation: '',
      currencyType: '',
      selectPriority: '',
      processingSiteCode: '',
      selectSiteCode: '',
      deleteClient: '',
      DeleteCarrierName: '',
      Deletecarrierid: '',
      DeletemodelTrained: '',
      DeleteselectSiteCode: '',
      DeleteprocessingSiteCode: '',
      DeleteselectPriority: '',
      DeletecurrencyType: '',
      DeleteclientLocation: '',
      DeleteaccountNumber: '',
      scacEmail: '',
      clientidEmail: '',
      senderEmail: '',
      deletescacEmail: '',
      scacEmail1: '',
      deleteclientidEmail: '',
      deletesenderEmail: '',
      clientEmail: '',
      keyWord: '',
      deleteClientEmail: '',
      keyWord1: '',
      clientEmail1: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(scacCodeSchema),
  });

  const updateScac = (data: any) => {
    if (watch('reason') === 'scac' && watch('reasonAction') === 'insert') {
      setLoader(true);
      const headers = {
        Accept: 'application/json',
      };
      const scac = {
        table_name: TABLE_NAME.scac,
        args: {
          scac: data['scac'],
          carrier_id: data['carrierid'],
          carrier_name: data['carrierName'],
          model_trained: data['modelTrained'],
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/insert_data', scac, { headers })
        .then(() => {
          toast.success('SCAC Code inserted Successfully');
          setLoader(false);
          setValue('scac', '');
          setValue('carrierid', '');
          setValue('carrierName', '');
          setValue('modelTrained', '');
        })
        .catch((err) => {
          setLoader(false);
          toast.error('You cannot Insert Already Existing SCAC Code');
          console.log(err);
        });
    }
    if (watch('reason') === 'scac' && watch('reasonAction') === 'inactive') {
      setLoader(true);
      const headers = {
        Accept: 'application/json',
      };
      const scac = {
        table_name: TABLE_NAME.scac,
        args: {
          scac: data['deleteScac'],
          carrier_id: data['Deletecarrierid'],
          carrier_name: data['DeleteCarrierName'],
          model_trained: data['DeletemodelTrained'],
          status: 'InActive',
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/update_row', scac, { headers })
        .then(() => {
          setLoader(false);
          setModalOpen(false);
          toast.success('SCAC Code De-Activated Successfully');
          setValue('deleteScac', '');
          setValue('Deletecarrierid', '');
          setValue('DeleteCarrierName', '');
          setValue('DeletemodelTrained', '');
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
    if (watch('reason') === 'clientCode' && watch('reasonAction') === 'insert') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const clientCode = {
        table_name: TABLE_NAME.cilent,
        args: {
          client_code: data['clientCode'],
          account_name: data['accountNumber'],
          client_location: data['clientLocation'],
          currency_type: data['currencyType'],
          default_priority: data['selectPriority'],
          processing_site_code: data['processingSiteCode'],
          site_code: data['selectSiteCode'],
          status: 'Active',
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/insert_data', clientCode, { headers })
        .then(() => {
          setLoader(false);
          toast.success('Client Code inserted Successfully');
          setValue('clientCode', '');
          setValue('accountNumber', '');
          setValue('clientLocation', '');
          setValue('currencyType', '');
          setValue('selectPriority', '');
          setValue('processingSiteCode', '');
          setValue('selectSiteCode', '');
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
    if (watch('reason') === 'clientCode' && watch('reasonAction') === 'inactive') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const clientCode = {
        table_name: TABLE_NAME.cilent,
        args: {
          client_code: data['deleteClient'],
          account_name: data['DeleteaccountNumber'],
          client_location: data['DeleteclientLocation'],
          currency_type: data['DeletecurrencyType'],
          default_priority: data['DeleteselectPriority'],
          processing_site_code: data['DeleteprocessingSiteCode'],
          site_code: data['selectSiteCode'],
          status: 'InActive',
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/update_row', clientCode, { headers })
        .then(() => {
          setLoader(false);
          setModalOpen(false);
          toast.success('Client Code deleted Successfully');
          setValue('deleteClient', '');
          setValue('DeleteaccountNumber', '');
          setValue('DeleteclientLocation', '');
          setValue('DeletecurrencyType', '');
          setValue('DeleteselectPriority', '');
          setValue('DeleteprocessingSiteCode', '');
          setValue('DeleteselectSiteCode', '');
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
    if (watch('reason') === 'scac&Email' && watch('reasonAction') === 'insert') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const scacEmail = {
        table_name: TABLE_NAME.scacEmail,
        args: {
          client_id: data['clientidEmail'],
          scac: data['scacEmail'],
          sender_email_id: data['senderEmail'],
        },
      };
      axios.post('https://d2lreports.itechlab.in/insert_data', scacEmail, { headers }).then(() => {
        setLoader(false);
        toast.success('Scac&Email inserted successfully');
        setValue('clientidEmail', '');
        setValue('scacEmail', '');
        setValue('senderEmail', '');
      });
    }
    if (watch('reason') === 'scac&Email' && watch('reasonAction') === 'inactive') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const ScacDelete = {
        table_name: TABLE_NAME.scacEmail,
        args: {
          meta_data_to_update: data['deletescacEmail'],
          client_id: data['deleteclientidEmail'],
          scac: data['scacEmail1'],
          sender_email_id: data['deletesenderEmail'],
          status: 'Inactive',
        },
      };
      axios.post('https://d2lreports.itechlab.in/update_row', ScacDelete, { headers }).then(() => {
        setLoader(false);
        setModalOpen(false);
        toast.success('Scac & Email Inactivated successfully');
        setValue('deletescacEmail', '');
        setValue('deleteclientidEmail', '');
        setValue('scacEmail1', '');
        setValue('deletesenderEmail', '');
      });
    }
    if (watch('reason') === 'ClientCode&Key' && watch('reasonAction') === 'insert') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const clientEmail = {
        table_name: TABLE_NAME.clientKey,
        args: {
          client_code_indexed: data['clientEmail'],
          keyword: data['keyWord'],
          status: 'Active',
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/insert_data', clientEmail, { headers })
        .then(() => {
          setLoader(false);
          toast.success('client key inserted successfully');
          setValue('clientEmail', '');
          setValue('keyWord', '');
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (watch('reason') === 'ClientCode&Key' && watch('reasonAction') === 'inactive') {
      setLoader(false);
      const headers = {
        Accept: 'application/json',
      };
      const clientEmail = {
        table_name: TABLE_NAME.clientKey,
        args: {
          meta_data_to_update: data['deleteClientEmail'],
          client_code_indexed: data['clientEmail1'],
          keyword: data['keyWord1'],
          status: 'Inactive',
        },
      };
      axios
        .post('https://d2lreports.itechlab.in/update_row', clientEmail, { headers })
        .then(() => {
          setLoader(false);
          setModalOpen(false);
          toast.success('client key Inactivated successfully');
          setValue('deleteClientEmail', '');
          setValue('clientEmail1', '');
          setValue('keyWord1', '');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const scacListInfo = async () => {
    try {
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.scac}&sort_by=scac&filters=${JSON.stringify(
          {
            meta_data: ['contains', scacFilter],
          },
        )}`,
      )
        .then((resp) => {
          const removeDuplicateScac = [...scacData, ...resp];

          setScacData(_.uniqBy(removeDuplicateScac, 'scac'));
        })
        .catch((err) => {
          console.log(err);
        });
      // const scacDataAPI: any = await API.graphql(
      //   graphqlOperation(listScacCarrierInformations, {
      //     filter: {
      //       meta_data: { contains: scacFilter },
      //     },
      //     limit: 900,
      //   }),
      // );

      // const removeDuplicateScac = [];
      // scacDataAPI?.data?.listScacCarrierInformations?.items.forEach((data: any) => {
      //   removeDuplicateScac.push(data);
      // });
      // setScacData(_.uniq(removeDuplicateScac));
    } catch (error) {
      console.log(error);
    }
  };

  const scacListEmail = async () => {
    try {
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${
          TABLE_NAME.scacEmail
        }&sort_by=scac&filters=${JSON.stringify({
          meta_data: ['contains', scacFilter1],
        })}`,
      )
        .then((resp) => {
          const removeDuplicateScac = [...scacData1, ...resp];

          setScacData1(_.uniqBy(removeDuplicateScac, 'meta_data'));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const clientListEmail = async () => {
    try {
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.clientKey}&filters=${JSON.stringify({
          meta_data: ['contains', clientEmailFilter],
        })}&sort_by=client_code_indexed&asc=${true}`,
      )
        .then((resp) => {
          console.log(resp);

          const removeDuplicateScac = [...clientEmailData, ...resp];

          setClientEmailData(_.uniqBy(removeDuplicateScac, 'meta_data'));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const logisticsClientInfo = async () => {
    try {
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.cilent}&filters=${JSON.stringify({
          meta_data: ['contains', clientFilter],
        })}&sort_by=client_code&asc=${true}`,
      )
        .then((res) => {
          const removeDuplicateClient = [...logisticsData, ...res];
          setLogisticsData(_.uniqBy(removeDuplicateClient, 'client_code'));
        })
        .catch((err) => {
          console.log(err);
        });

      // const logisticsData: any = await API.graphql(
      //   graphqlOperation(listLogisticsClientInformations, {
      //     filter: {
      //       meta_data: {
      //         contains: clientFilter,
      //       },
      //       ...(tabType === 'pending' && clientLocation
      //         ? {
      //             client_location: {
      //               eq: clientLocation === 'EUR-TEST' ? 'EU' : 'US',
      //             },
      //           }
      //         : ''),
      //     },
      //     limit: 900,
      //   }),
      // );
      // setLogisticsData(
      //   logisticsData?.data?.listLogisticsClientInformations?.items.sort(
      //     (a: number, b: number) => b - a,
      //   ),
      // );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    scacListInfo();
    setScacData([]);
  }, [scacFilter]);

  useEffect(() => {
    logisticsClientInfo();
  }, [clientFilter]);

  useEffect(() => {
    scacListEmail();
  }, [scacFilter1]);

  useEffect(() => {
    clientListEmail();
  }, [clientEmailFilter]);

  return (
    <Typography component={'div'}>
      <Header activeIndex={3} />
      <Loader openLoader={loader} />

      {/* <iframe
        style={{ height: '95vh', width: '98vw', margin: '10px' }}
        src='https://d2ldatainterface.itechlab.in/'></iframe> */}
      <Typography component={'div'} className='innerbody'>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container justifyContent='center'>
              <Grid item md={7.5} sm={12} xs={12}>
                <Typography className='title20' textAlign='center'>
                  Configuration Table
                </Typography>
                <Typography component={'div'} className='fil-box'>
                  <Grid container columnSpacing={2.5} className='custom-form'>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-input'>
                        <Typography className='custom-label'>
                          Required Table <span>*</span>
                        </Typography>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=''
                            name='reason'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  options={requiredTable}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                  error={errors.reason?.message}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-input'>
                        <Typography className='custom-label'>
                          Required Action <span>*</span>
                        </Typography>
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            defaultValue=''
                            name='reasonAction'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  onChange={(e) => {
                                    onChange(e);
                                    setValue('scac', '');
                                    setValue('carrierName', '');
                                    // setValue('deleteClient', '');
                                    setValue('DeleteselectSiteCode', '');
                                    setValue('DeleteaccountNumber', '');
                                    setValue('DeleteprocessingSiteCode', '');
                                    setValue('DeleteselectPriority', '');
                                    setValue('DeletecurrencyType', '');
                                    setValue('DeleteclientLocation', '');
                                    setValue('DeleteCarrierName', '');
                                    setValue('Deletecarrierid', '');
                                    setValue('DeletemodelTrained', '');
                                  }}
                                  value={value}
                                  name={name}
                                  options={requiredAction}
                                  error={errors.reasonAction?.message}
                                  labelKey={'name'}
                                  valueKey={'id'}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'div'} className='line-parent mt-24'>
                  <Typography
                    component={'div'}
                    className='line-top d-flex-a'
                    sx={{ minHeight: '50px' }}>
                    <Typography component={'div'}>
                      <Typography
                        component={'div'}
                        className='title-18'
                        sx={{ marginBottom: '0px !important' }}>
                        D2L Carrier List Table
                      </Typography>
                    </Typography>
                  </Typography>
                  {watch('reason') === 'scac' && watch('reasonAction') === 'insert' ? (
                    <Typography component={'div'} className='line-body'>
                      <Grid container rowSpacing={2.5} className='custom-form'>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              SCAC <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='scac'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter SCAC Code'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.scac?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Carrier Name <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='carrierName'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Carrier Name'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.carrierName?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Carrier Id <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='carrierid'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Carrier Id'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.carrierid?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Model Tained Status
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='modelTrained'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select Model Tained Status'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={modelTrained}
                                      error={errors.modelTrained?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    watch('reason') === 'scac' &&
                    watch('reasonAction') === 'inactive' && (
                      <Typography component={'div'} className='line-body'>
                        <Grid container rowSpacing={2.5} className='custom-form'>
                          {' '}
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter SCAC Code to Delete <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='deleteScac'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select  Site Code'}
                                        name={name}
                                        value={value}
                                        labelKey={'meta_data'}
                                        valueKey={'scac'}
                                        options={scacData}
                                        error={errors.deleteScac?.message}
                                        onChange={(e) => {
                                          if (e?.target?.value) {
                                            const fIndex = scacData.findIndex(
                                              (item: any) => item.scac === e.target.value,
                                            );
                                            setValue(
                                              'DeleteCarrierName',
                                              scacData[fIndex].carrier_name,
                                            );
                                            setValue(
                                              'Deletecarrierid',
                                              scacData[fIndex].carrier_id,
                                            );
                                            setValue(
                                              'DeletemodelTrained',
                                              scacData[fIndex].model_trained,
                                            );
                                          } else {
                                            setValue('DeleteCarrierName', '');
                                            setValue('Deletecarrierid', '');
                                            setValue('DeletemodelTrained', '');
                                          }
                                          onChange(e);
                                        }}
                                        inputValue={scacFilter}
                                        onInputChange={(e) => {
                                          setScacFilter(e.toUpperCase());
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Carrier Name <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='DeleteCarrierName'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Carrier Name'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.DeleteCarrierName?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Carrier Id <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='Deletecarrierid'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Carrier Id'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.Deletecarrierid?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Model Tained Status <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeletemodelTrained'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select Model Tained Status'}
                                        name={name}
                                        value={value}
                                        disabled
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={modelTrained}
                                        error={errors.DeletemodelTrained?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )
                  )}
                  {watch('reason') === 'clientCode' && watch('reasonAction') === 'insert' ? (
                    <Typography component={'div'} className='line-body'>
                      <Grid container rowSpacing={2.5} className='custom-form'>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Client Code <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='clientCode'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Client Code'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.clientCode?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Account Name: <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='accountNumber'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Account Name'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.accountNumber?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Client Location <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='clientLocation'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select Client location'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={clientLoction}
                                      error={errors.clientLocation?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Currency Type <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='currencyType'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select Currency Type'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={clientType}
                                      error={errors.currencyType?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Priority <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='selectPriority'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select Priority'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={priority}
                                      error={errors.selectPriority?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Processing Site Code <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='processingSiteCode'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select Processing Site Code'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={siteCode}
                                      error={errors.processingSiteCode?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Site Code <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='selectSiteCode'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select  Site Code'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={siteCode1}
                                      error={errors.selectSiteCode?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid>
                        {/* <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Select Status <span>*</span>
                            </Typography>
                            <FormControl fullWidth>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='selectSiteCode'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={' Select Status'}
                                      name={name}
                                      value={value}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={status}
                                      error={errors.status?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Typography>
                  ) : (
                    watch('reason') === 'clientCode' &&
                    watch('reasonAction') === 'inactive' && (
                      <Typography component={'div'} className='line-body'>
                        <Grid container rowSpacing={2.5} className='custom-form'>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Client Code to Delete
                                <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='deleteClient'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select  Site Code'}
                                      name={name}
                                      value={value}
                                      labelKey={'meta_data'}
                                      valueKey={'client_code'}
                                      options={logisticsData}
                                      error={errors.deleteClient?.message}
                                      onChange={(e) => {
                                        if (e?.target?.value) {
                                          const clientInfo = logisticsData.findIndex(
                                            (item: any) => item.client_code === e.target.value,
                                          );
                                          setValue(
                                            'DeleteselectSiteCode',
                                            logisticsData[clientInfo].site_code,
                                          );
                                          setValue(
                                            'DeleteaccountNumber',
                                            logisticsData[clientInfo].account_name,
                                          );
                                          setValue(
                                            'DeleteprocessingSiteCode',
                                            logisticsData[clientInfo].processing_site_code,
                                          );
                                          setValue(
                                            'DeleteselectPriority',
                                            logisticsData[clientInfo].default_priority,
                                          );
                                          setValue(
                                            'DeletecurrencyType',
                                            logisticsData[clientInfo].currency_type,
                                          );
                                          setValue(
                                            'DeleteclientLocation',
                                            logisticsData[clientInfo].client_location,
                                          );
                                        } else {
                                          setValue('DeleteselectSiteCode', '');
                                          setValue('DeleteaccountNumber', '');
                                          setValue('DeleteprocessingSiteCode', '');
                                          setValue('DeleteselectPriority', '');
                                          setValue('DeletecurrencyType', '');
                                          setValue('DeleteclientLocation', '');
                                        }
                                        onChange(e);
                                      }}
                                      inputValue={clientFilter}
                                      onInputChange={(e) => {
                                        setClientFilter(e.toUpperCase());
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Account Name: <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='DeleteaccountNumber'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Account Name'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      className={'custom-textarea'}
                                      error={errors.DeleteaccountNumber?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Client location <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeleteclientLocation'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select Client location'}
                                        name={name}
                                        value={value}
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={clientLoction}
                                        error={errors.DeleteclientLocation?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Currency Type <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeletecurrencyType'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select Currency Type'}
                                        name={name}
                                        value={value}
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={clientType}
                                        error={errors.DeletecurrencyType?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Priority <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeleteselectPriority'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select Priority'}
                                        name={name}
                                        value={value}
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={priority}
                                        error={errors.DeleteselectPriority?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Processing Site Code <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeleteprocessingSiteCode'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select Processing Site Code'}
                                        name={name}
                                        value={value}
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={siteCode}
                                        error={errors.DeleteprocessingSiteCode?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Select Site Code <span>*</span>
                              </Typography>
                              <FormControl fullWidth>
                                <Controller
                                  control={control}
                                  defaultValue=''
                                  name='DeleteselectSiteCode'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomSelect
                                        id='outlined-basic'
                                        placeHolder={'Select  Site Code'}
                                        name={name}
                                        value={value}
                                        labelKey={'name'}
                                        valueKey={'id'}
                                        options={siteCode1}
                                        error={errors.DeleteselectSiteCode?.message}
                                        onChange={onChange}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )
                  )}
                  {watch('reason') === 'scac&Email' && watch('reasonAction') === 'insert' ? (
                    <Typography component={'div'} className='line-body'>
                      <Grid container rowSpacing={2.5} className='custom-form'>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Enter SCAC <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='scacEmail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter SCAC Code'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.scacEmail?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>Enter Client ID</Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='clientidEmail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Carrier Id'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.clientidEmail?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Sender Email <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='senderEmail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Carrier Name'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.senderEmail?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    watch('reason') === 'scac&Email' &&
                    watch('reasonAction') === 'inactive' && (
                      <Typography component={'div'} className='line-body'>
                        <Grid container rowSpacing={2.5} className='custom-form'>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Client Id & Email <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='deletescacEmail'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select '}
                                      name={name}
                                      value={value}
                                      labelKey={'meta_data'}
                                      valueKey={'meta_data'}
                                      options={scacData1}
                                      error={errors.deletescacEmail?.message}
                                      onChange={(e) => {
                                        if (e?.target?.value) {
                                          const scacEmail = scacData1.findIndex(
                                            (item: any) => item.meta_data === e?.target?.value,
                                          );
                                          setValue('scacEmail1', scacData1[scacEmail].scac);
                                          setValue(
                                            'deleteclientidEmail',
                                            scacData1[scacEmail].client_id,
                                          );
                                          setValue(
                                            'deletesenderEmail',
                                            scacData1[scacEmail].sender_email_id,
                                          );
                                        } else {
                                          setValue('scacEmail1', '');
                                          setValue('deleteclientidEmail', '');
                                          setValue('deletesenderEmail', '');
                                        }
                                        onChange(e);
                                      }}
                                      inputValue={scacFilter1}
                                      onInputChange={(e) => {
                                        setScacFilter1(e.toUpperCase());
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter SCAC <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='scacEmail1'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter SCAC Code'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.scacEmail1?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Client ID <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='deleteclientidEmail'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Carrier Id'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.deleteclientidEmail?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Sender Email <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='deletesenderEmail'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Carrier Name'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      className={'custom-textarea'}
                                      disabled
                                      error={errors.deletesenderEmail?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )
                  )}
                  {watch('reason') === 'ClientCode&Key' && watch('reasonAction') === 'insert' ? (
                    <Typography component={'div'} className='line-body'>
                      <Grid container rowSpacing={2.5} className='custom-form'>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Enter Client Code <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='clientEmail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter  Client Code'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.clientEmail?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-input'>
                            <Typography className='custom-label'>
                              Enter Keyword <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue=''
                              name='keyWord'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    id='outlined-basic'
                                    placeHolder={'Enter Keyword'}
                                    fullWidth
                                    variant='outlined'
                                    name={name}
                                    value={value}
                                    className={'custom-textarea'}
                                    error={errors.keyWord?.message}
                                    onChange={onChange}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    watch('reason') === 'ClientCode&Key' &&
                    watch('reasonAction') === 'inactive' && (
                      <Typography component={'div'} className='line-body'>
                        <Grid container rowSpacing={2.5} className='custom-form'>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Client Key Id <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='deleteClientEmail'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      id='outlined-basic'
                                      placeHolder={'Select '}
                                      name={name}
                                      value={value}
                                      labelKey={'meta_data'}
                                      valueKey={'meta_data'}
                                      options={clientEmailData}
                                      error={errors.deleteClientEmail?.message}
                                      onChange={(e) => {
                                        if (e?.target?.value) {
                                          const clientEmail = clientEmailData.findIndex(
                                            (item: any) => item.meta_data === e?.target?.value,
                                          );
                                          setValue(
                                            'clientEmail1',
                                            clientEmailData[clientEmail].client_code_indexed,
                                          );
                                          setValue(
                                            'keyWord1',
                                            clientEmailData[clientEmail].keyword,
                                          );
                                        } else {
                                          setValue('clientEmail1', '');
                                          setValue('keyWord1', '');
                                        }
                                        onChange(e);
                                      }}
                                      inputValue={clientEmailFilter}
                                      onInputChange={(e) => {
                                        setClientEmailFilter(e.toUpperCase());
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Client Code <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='clientEmail1'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Carrier Code'}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.clientEmail1?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-input'>
                              <Typography className='custom-label'>
                                Enter Keyword <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue=''
                                name='keyWord1'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      id='outlined-basic'
                                      placeHolder={'Enter Keyword '}
                                      fullWidth
                                      variant='outlined'
                                      name={name}
                                      value={value}
                                      disabled
                                      className={'custom-textarea'}
                                      error={errors.keyWord1?.message}
                                      onChange={onChange}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    )
                  )}
                  <Button
                    className='save-btn'
                    onClick={handleSubmit(
                      watch('reasonAction') === 'inactive' ? handleOpen : updateScac,
                    )}>
                    {watch('reasonAction') === 'insert'
                      ? 'Insert'
                      : watch('reasonAction') === 'inactive'
                      ? 'inactive'
                      : ''}
                  </Button>
                  <Modal
                    open={modalOpen}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        height: 200,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 5,
                      }}>
                      <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                        className='d-flex-ja'>
                        Are you sure you want to InActive?
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          paddingTop: '40px',
                        }}>
                        <Typography>
                          <Button variant='contained' onClick={() => setModalOpen(false)}>
                            No
                          </Button>
                        </Typography>
                        <Typography>
                          <Button
                            variant='contained'
                            color='error'
                            onClick={handleSubmit(updateScac)}>
                            yes
                          </Button>
                        </Typography>
                      </Typography>
                    </Box>
                  </Modal>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
    </Typography>
  );
};

export default ConFiguration;
