/* eslint-disable camelcase */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Loader from 'components/Loader';
import { getAllListData } from 'services/commomService';
import { SETUP_ENDPOINT, TABLE_NAME } from 'utils/constant';
import moment from 'moment';
import dayjs from 'dayjs';
import * as xlsx from 'xlsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import DownloadIcon from 'assets/icon/downloadicon.svg';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { Menu as SlideMenu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import ImageViewer from 'pages/ImageViewer';
// import CompleteView from 'pages/CompleteVIew';
// import { toast } from 'react-toastify';
const MENU_ID = 'rightMenu';

const RightMenu = (props: { menuId: string; copy: string; data?: any }) => {
  const { menuId, copy, data } = props;
  return (
    <SlideMenu id={menuId} style={{ background: '#eee', boxShadow: '0 4px 4px -6px grey' }}>
      <Item id={copy} onClick={handleItemClick}>
        Copy
      </Item>
      <Item id='copyHeader' onClick={copyHeaderClick}>
        Copy with header
      </Item>
      <Item id='paste' disabled>
        Paste
      </Item>
      <Separator />

      <Submenu label='Export' style={{ background: '#eee', boxShadow: '0 4px 4px -6px grey' }}>
        <Item id='csvExport' onClick={() => exportExcel(data, 'csv')}>
          Export (.csv)
        </Item>
        <Item id='excelXlsx' onClick={() => exportExcel(data, 'xlsx')}>
          Export (.xlsx)
        </Item>
        <Item id='excelXml' disabled>
          Export (.xml)
        </Item>
      </Submenu>
    </SlideMenu>
  );
};

const handleItemClick = async (props: any) => {
  const { props: prop } = props;
  if (navigator.clipboard && window.isSecureContext) {
    return await navigator.clipboard.writeText(`${prop['row']['signed_email_id']},${prop['key']}`);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea');
    textArea.value = `${prop['row']['signed_email_id']},${prop['key']}`;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};
const exportExcel = (data: any, fileType: string) => {
  const overAllData = data?.map((exportData: any) => {
    exportData.created = moment.unix(exportData.created).format('DD-MM-YYYY hh:mm a');
    return exportData;
  });

  const finalExportData = _.map(overAllData, (filterData) => {
    const propertyNames = [
      'signed_email_id',
      'email',
      'indexing_status',
      'created',
      'client_code',
      'scac',
      'description',
      'pages',
    ];
    return _.pick(filterData, propertyNames);
  });

  const workbook = xlsx.utils.book_new();

  const worksheet = xlsx.utils.json_to_sheet(finalExportData);

  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelData: any = xlsx.write(workbook, {
    bookType: fileType === 'xlsx' ? 'xlsx' : 'csv',
    type: fileType === 'xlsx' ? 'array' : 'string',
  });

  const blob = new Blob([excelData], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${
    overAllData[0]?.priority === 2 ? 'priority' : finalExportData[0]?.task_status
  }_table_data.${fileType}`;
  document.body.appendChild(link);
  link.click();
};
const copyHeaderClick = async (props: any) => {
  const { props: prop } = props;
  if (navigator.clipboard && window.isSecureContext) {
    return await navigator.clipboard.writeText(
      `${prop['header']} ${prop['row']['signed_email_id']},${prop['key']}`,
    );
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = `${prop['header']} ${prop['row']['signed_email_id']},${prop['key']}`;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
      left: '474px',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const TextOnlyTooltip = withStyles({
  tooltip: {
    color: 'black !important',
    backgroundColor: 'whiteSmoke !important',
    fontSize: '15px !important',
    boxShadow: '0 0 8px 0px grey !important',
  },
})(Tooltip);
export const IclarifyItem = () => {
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const topScroll = React.useRef<any>(null);
  const [clarifyTableData, setClarifyTableData] = useState<any>([]);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [modelTrain, setModalTrain] = React.useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [expandInfo, setExpandInfo] = React.useState<any>({});
  const [overallSearch, setOverallSearch] = React.useState<string>('');
  const [iclarifyModal, setIclarifyModal] = React.useState<boolean>(false);
  const [overallData, setOverallData] = useState<any>({});
  const [iclarifyFilterParams, setIclarifyFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    created: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    asc: true,
    sortby: 'created',
  });
  const [tiffFile, setTiffFile] = React.useState<any>();
  const [nextToken, setNextToken] = React.useState<number>(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const useStyles = makeStyles({
    customTextField: {
      '& input::placeholder': {
        fontSize: '13px',
      },
    },
  });

  const toggleAcordion = (indexId: number) => {
    setExpandInfo((pre) => ({
      ...pre,
      [indexId]: !pre[indexId],
    }));
  };

  const uriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = match[2];
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/batching/${key}`;
    } else {
      return null;
    }
  };

  const sortTableData = (value?: string, sort?: boolean) => {
    setIclarifyFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
  };

  const handleContextMenu = (event, row, copiedValue, header) => {
    show({
      event,
      props: {
        key: copiedValue,
        row: row,
        header: header,
      },
    });
  };

  const clearFilter = () => {
    setClarifyTableData([]);
    setNextToken(1);
    setOverallSearch('');
    setIclarifyFilterParams({
      email: '',
      source: '',
      status: '',
      created: '',
      client: '',
      scac: '',
      description: '',
      pages: '',
      asc: true,
      sortby: 'created',
    });
  };
  const searchFilterData = async () => {
    setClarifyTableData([]);
    setGetMoreLoader(false);
    setLoader(true);
    const count = Number(1);
    setNextToken(count);
    const filterInputs: any = {
      ...(iclarifyFilterParams?.email ? { email: iclarifyFilterParams?.email } : ''),
      ...(iclarifyFilterParams?.source ? { source: iclarifyFilterParams?.source } : ''),
      ...(iclarifyFilterParams?.pages ? { pages: iclarifyFilterParams?.pages } : ''),
      ...(iclarifyFilterParams?.status ? { indexing_status: iclarifyFilterParams?.status } : ''),
      ...(iclarifyFilterParams?.created
        ? {
            created: {
              start_date: moment.unix(iclarifyFilterParams?.created).startOf('day').utc().unix(),
              end_date: moment.unix(iclarifyFilterParams?.created).endOf('day').utc().unix(),
            },
          }
        : ''),
      ...(iclarifyFilterParams?.client ? { client_code: iclarifyFilterParams?.client } : ''),
      ...(iclarifyFilterParams?.scac ? { scac: iclarifyFilterParams?.scac } : ''),
      ...(iclarifyFilterParams?.description
        ? { description: iclarifyFilterParams?.description }
        : ''),
    };

    await getAllListData(
      `${SETUP_ENDPOINT.clarifySearch}?search_term=${overallSearch}&filters=${JSON.stringify(
        filterInputs,
      )}&page=${count}&asc=${iclarifyFilterParams?.asc}&sort_by=${iclarifyFilterParams?.sortby}`,
    ).then((res) => {
      Array.isArray(res?.search_results) &&
        res.search_results.length > 0 &&
        setClarifyTableData(res.search_results);
      setLoader(false);
    });
  };
  const clarifyGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(nextToken + 1);
    setNextToken(count);
    const filterInputs: any = {
      ...(iclarifyFilterParams?.email ? { email: iclarifyFilterParams?.email } : ''),
      ...(iclarifyFilterParams?.source ? { source: iclarifyFilterParams?.source } : ''),
      ...(iclarifyFilterParams?.pages ? { pages: iclarifyFilterParams?.pages } : ''),
      ...(iclarifyFilterParams?.status ? { indexing_status: iclarifyFilterParams?.status } : ''),
      ...(iclarifyFilterParams?.created
        ? {
            created: {
              start_date: moment.unix(iclarifyFilterParams?.created).startOf('day').utc().unix(),
              end_date: moment.unix(iclarifyFilterParams?.created).endOf('day').utc().unix(),
            },
          }
        : ''),
      ...(iclarifyFilterParams?.client ? { client_code: iclarifyFilterParams?.client } : ''),
      ...(iclarifyFilterParams?.scac ? { scac: iclarifyFilterParams?.scac } : ''),
      ...(iclarifyFilterParams?.description
        ? { description: iclarifyFilterParams?.description }
        : ''),
    };

    await getAllListData(
      `${SETUP_ENDPOINT.clarifySearch}?search_term=${overallSearch}&filters=${JSON.stringify(
        filterInputs,
      )}&page=${count}&asc=${iclarifyFilterParams?.asc}&sort_by=${iclarifyFilterParams?.sortby}`,
    ).then((res) => {
      Array.isArray(res?.search_results) &&
        res.search_results.length > 0 &&
        setClarifyTableData((pre) => [...pre, ...res.search_results]);
      setGetMoreLoader(false);
    });
  };

  const encodeUriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = encodeURIComponent(match[2]);
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/${key}`;
    } else {
      return null;
    }
  };

  const documentViewData = (data: any) => {
    setIclarifyModal(true);
    if (data?.indexing_status === 'indexing_completed') {
      if (JSON.parse(data?.task_info)?.split_info.length === 1) {
        setOverallData(JSON.parse(data?.task_info)?.split_info[0]);
      } else {
        const completedBindData = [];
        JSON.parse(data?.task_info)?.split_info?.forEach((completedData: any) => {
          completedData?.forEach((bindData: any) => {
            completedBindData.push(bindData);
          });
        });
        setOverallData(completedBindData);
      }
    } else {
      setOverallData(JSON.parse(data?.task_info)?.split_info);
    }
  };

  const menuOpen = (event: React.MouseEvent<HTMLElement>, data?: any) => {
    setTiffFile(data);
    setAnchorEl(event.currentTarget);
  };

  const menuClose = () => {
    setAnchorEl(null);
    setTiffFile(null);
  };

  const getData = async () => {
    getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.scac}&sort_by=scac&filters=${JSON.stringify({
        // eslint-disable-next-line camelcase
        model_trained: ['eq', 'yes'],
      })}&page_size=500`,
    )
      .then((res: any) => {
        const scacTrainedList = res?.map((data) => data.scac);
        setModalTrain(scacTrainedList && Array.isArray(scacTrainedList) ? scacTrainedList : []);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    setTimeout(() => {
      topScroll?.current?.scroll({ top: 0, behavior: 'smooth' });
      setNextToken(1);
      setClarifyTableData([]);
      searchFilterData();
    }, 500);
  }, [iclarifyFilterParams]);

  useEffect(() => {
    getData();
  }, []);
  const classes = useStyles();
  return (
    <Typography component={'div'}>
      <Typography
        sx={{
          height: '12vh',
          display: 'flex',
          width: '100vw',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography>
          <TextField
            label='Search an email/ftp by Email ID, Subject, Document ID, Attachment Name/File name'
            type='search'
            value={overallSearch}
            onChange={(e) => {
              setOverallSearch(e.target.value);
            }}
            variant='standard'
            sx={{ width: '600px' }}
          />
        </Typography>
        <Typography>
          <Button
            sx={{ marginTop: '15px', marginLeft: '13px' }}
            variant='outlined'
            onClick={searchFilterData}>
            Search
          </Button>
        </Typography>
        <Typography>
          <Button
            sx={{ marginTop: '15px', marginLeft: '13px' }}
            variant='outlined'
            onClick={clearFilter}>
            Clear Filter
          </Button>
        </Typography>
      </Typography>
      <Typography>
        <TableContainer
          className='index-table'
          ref={topScroll}
          id='scrollParent1'
          style={{
            height: 'calc(100vh - 165px)',
            overflow: 'auto',
          }}>
          <InfiniteScroll
            style={{ overflow: 'unset' }}
            dataLength={Number(clarifyTableData?.length)}
            pullDownToRefreshThreshold={50}
            next={clarifyGetMoreList}
            hasMore={true}
            scrollableTarget={'scrollParent1'}
            loader={
              getMoreLoader && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{
                      color: '#002c69',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  />
                </div>
              )
            }
            endMessage>
            <Table aria-label='collapsible table' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='w-15'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Email/FTP File Name</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('email', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('email', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography> Source</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('source', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('source', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Status</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('indexing_status', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('indexing_status', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>

                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Created Date</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('created', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('created', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-8'>
                    <TextOnlyTooltip arrow title={'Client Code'}>
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography>Client..</Typography>
                        <Typography className='p-relative'>
                          <span
                            className='upArrow sortIcon pointer'
                            onClick={() => sortTableData('client_code', true)}>
                            ▲
                          </span>
                          <span
                            className='downArrow sortIcon pointer'
                            onClick={() => sortTableData('client_code', false)}>
                            ▼
                          </span>
                        </Typography>
                      </Typography>
                    </TextOnlyTooltip>
                  </TableCell>
                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Scac</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('scac', true)}>
                          {' '}
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('scac', false)}>
                          {' '}
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Description</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('description', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('description', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className='w-8'>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>Pages</Typography>
                      <Typography className='p-relative'>
                        <span
                          className='upArrow sortIcon pointer'
                          onClick={() => sortTableData('pages', true)}>
                          ▲
                        </span>
                        <span
                          className='downArrow sortIcon pointer'
                          onClick={() => sortTableData('pages', false)}>
                          ▼
                        </span>
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: '3%' }}>More</TableCell>
                </TableRow>
                <TableRow className='back-color'>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        // classes={{ root: classes.customTextField }}
                        // label='Search Email'
                        // variant='outlined'
                        placeholder='Email'
                        value={iclarifyFilterParams?.email}
                        onChange={(e) =>
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }

                        // onChange={(e) => {
                        //   setCompletedFilterParams((prev: any) => ({
                        //     ...prev,
                        //     email: e.target.value,
                        //   }));
                        //   setCompletedCookies('email', e.target.value);
                        // }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        // label='Search Source'
                        // variant='outlined'
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            source: e.target.value,
                          }));
                        }}
                        value={iclarifyFilterParams?.source}
                        placeholder='Source'
                      />
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        // label='Search Status'
                        // variant='outlined'
                        value={iclarifyFilterParams?.status}
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            status: e.target.value,
                          }));
                        }}
                        placeholder='Status'
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          disableFuture
                          format='DD/MM/YYYY'
                          value={
                            iclarifyFilterParams?.created
                              ? dayjs(moment.unix(iclarifyFilterParams?.created).format('L'))
                              : null
                          }
                          onChange={(e) => {
                            const date = moment(e.toDate()).format('L');
                            setIclarifyFilterParams((prev: any) => ({
                              ...prev,
                              created: date ? moment(date).unix() : null,
                            }));
                          }}
                        />
                      </LocalizationProvider>
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        // label='Search Client'
                        // variant='outlined'
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            client: e.target.value,
                          }));
                        }}
                        value={iclarifyFilterParams?.client}
                        placeholder='Client Code'
                      />
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        value={iclarifyFilterParams?.scac}
                        placeholder='Scac'
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            scac: e.target.value,
                          }));
                        }}
                        // label='Search SCAC'
                        // variant='outlined'
                      />
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        value={iclarifyFilterParams?.description}
                        // label='Search Description'
                        // variant='outlined'
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            description: e.target.value,
                          }));
                        }}
                        placeholder='Description'
                      />
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <TextField
                        classes={{ root: classes.customTextField }}
                        value={iclarifyFilterParams?.pages}
                        // label='Search Pages'
                        // variant='outlined'
                        onChange={(e) => {
                          setIclarifyFilterParams((prev: any) => ({
                            ...prev,
                            pages: e.target.value,
                          }));
                        }}
                        placeholder='Pages'
                        // sx={{ width: '50px' }}
                      />
                      {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loader ? (
                  <Loader openLoader={loader} />
                ) : (
                  clarifyTableData?.length > 0 &&
                  clarifyTableData?.map((row: any, index: number) => (
                    <>
                      <TableRow
                        className='pointer'
                        onClick={() => documentViewData(row)}
                        key={index}
                        sx={{
                          backgroundColor:
                            row.clone_status === 'yes'
                              ? '#ffffb7!important'
                              : index % 2 && '#fafffb!important',
                        }}>
                        <TableCell
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                          }>
                          <Typography component={'div'} className='d-flex-a'>
                            {' '}
                            {/* <Checkbox /> */}
                            <Typography className='d-flex-a mail-txt'>
                              {row?.batched_result !== '' && (
                                <Typography component={'div'} className='syncbox'>
                                  <Typography component={'span'} />
                                </Typography>
                              )}
                              {row?.email}
                              <RightMenu
                                menuId={`${MENU_ID}`}
                                copy={'copy'}
                                data={clarifyTableData}
                              />
                            </Typography>{' '}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align='center'
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.source || '', 'Source')
                          }>
                          {row?.source || '--'}
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>
                        <TableCell
                          align='center'
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.indexing_status || '', 'Status')
                          }>
                          {row?.indexing_status || '--'}
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>
                        <TableCell
                          onContextMenu={(e) =>
                            handleContextMenu(
                              e,
                              row,
                              moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '',
                              'Created',
                            )
                          }>
                          <TextOnlyTooltip arrow title={''}>
                            <div
                              style={{
                                width: 150,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                              }}>
                              {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                            </div>
                          </TextOnlyTooltip>
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>
                        <TableCell
                          align='center'
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                          }>
                          {row?.client_code || '--'}
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: modelTrain.includes(row?.scac) ? 'green' : 'red',
                          }}
                          align='center'
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.scac || '', 'SCAC Code')
                          }>
                          {row?.scac || '--'}
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>
                        <TableCell
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.description || '', 'Description')
                          }>
                          <TextOnlyTooltip arrow title={row?.description}>
                            <div
                              style={{
                                width: 150,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                              }}>
                              {row?.description || '--'}
                            </div>
                          </TextOnlyTooltip>
                          {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                        </TableCell>
                        <TableCell
                          align='center'
                          onContextMenu={(e) =>
                            handleContextMenu(e, row, row?.pages || '', 'Pages')
                          }>
                          {row?.pages || '--'}
                        </TableCell>

                        <TableCell onClick={(e) => e.stopPropagation()}>
                          <Typography component={'div'} onClick={() => toggleAcordion(index)}>
                            {expandInfo[index] ? (
                              <Typography component={'div'} className='d-flex-a pointer'>
                                <KeyboardArrowUpIcon />
                                <Typography>More</Typography>{' '}
                              </Typography>
                            ) : (
                              <Typography component={'div'} className='d-flex-a pointer'>
                                <KeyboardArrowDownIcon />
                                <Typography>More</Typography>
                              </Typography>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}
                          colSpan={12}>
                          <Collapse in={expandInfo[index]} timeout='auto' unmountOnExit>
                            {row.batched_result === 'None' ? (
                              <Box sx={{ margin: 1 }} key={index}>
                                <Typography component={'div'} className='inner-table-row d-flex-a'>
                                  <Grid container>
                                    <Grid md={3} sm={12}>
                                      <Typography className='inner-title'>Batch Name</Typography>
                                      <Typography>{'--'}</Typography>
                                    </Grid>
                                    <Grid md={3} sm={12}>
                                      <Typography className='inner-title d-flex-a'>
                                        File Name
                                      </Typography>
                                      <Typography>
                                        <TextOnlyTooltip
                                          arrow
                                          title={
                                            !_.isEmpty(clarifyTableData[index]?.file_source)
                                              ? JSON.parse(clarifyTableData[index]?.file_source)
                                                  ?.email_meta_data_path?.split('/')
                                                  ?.pop()
                                              : '--'
                                          }>
                                          <div
                                            style={{
                                              width: 300,
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                            }}>
                                            {!_.isEmpty(clarifyTableData[index]?.file_source)
                                              ? JSON.parse(clarifyTableData[index]?.file_source)
                                                  ?.email_meta_data_path?.split('/')
                                                  ?.pop()
                                              : '--'}
                                          </div>
                                        </TextOnlyTooltip>
                                      </Typography>
                                    </Grid>
                                    <Grid md={1} sm={12}>
                                      <Typography className='inner-title'>Image ID</Typography>
                                      <Typography>{'--'}</Typography>
                                    </Grid>
                                    <Grid md={4.5} sm={12} className='d-flex-a flex-end'>
                                      <Typography component={'div'} className='d-flex-a'>
                                        <Button
                                          className='download-btn mr-20'
                                          id='demo-customized-button'
                                          disableElevation
                                          onClick={() => {
                                            window.open(
                                              encodeUriTourl(
                                                JSON.parse(clarifyTableData[index]?.file_source)
                                                  .raw_pdf_path
                                                  ? JSON.parse(clarifyTableData[index]?.file_source)
                                                      .raw_pdf_path
                                                  : JSON.parse(clarifyTableData[index]?.file_source)
                                                      .attachment,
                                              ),
                                              '_blank',
                                            );
                                            menuClose();
                                          }}>
                                          <img src={DownloadIcon} alt='download' className='mr-7' />
                                          Download
                                        </Button>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </Box>
                            ) : row?.batched_result !== '' && JSON.parse(row?.batched_result) ? (
                              JSON.parse(row?.batched_result).map((data: any, index: number) => {
                                return (
                                  <Box sx={{ margin: 1 }} key={index}>
                                    <Typography
                                      component={'div'}
                                      className='inner-table-row d-flex-a'>
                                      <Grid container>
                                        <Grid md={3} sm={12}>
                                          <Typography className='inner-title'>
                                            Batch Name
                                          </Typography>
                                          <Typography>{data?.batchName || '--'}</Typography>
                                        </Grid>
                                        <Grid md={3} sm={12}>
                                          <Typography className='inner-title d-flex-a'>
                                            File Name
                                          </Typography>
                                          <Typography>
                                            <TextOnlyTooltip
                                              arrow
                                              title={
                                                !_.isEmpty(row?.file_source)
                                                  ? JSON.parse(JSON.parse(row?.file_source))
                                                      ?.email_meta_data_path?.split('/')
                                                      ?.pop()
                                                  : '--'
                                              }>
                                              <div
                                                style={{
                                                  width: 300,
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                }}>
                                                {!_.isEmpty(row?.file_source)
                                                  ? JSON.parse(JSON.parse(row?.file_source))
                                                      ?.email_meta_data_path?.split('/')
                                                      ?.pop()
                                                  : '--'}
                                              </div>
                                            </TextOnlyTooltip>
                                          </Typography>
                                        </Grid>
                                        <Grid md={1} sm={12}>
                                          <Typography className='inner-title'>Image ID</Typography>
                                          <Typography>{data?.imageId || '--'}</Typography>
                                        </Grid>
                                        <Grid md={4.5} sm={12} className='d-flex-a flex-end'>
                                          <Typography component={'div'} className='d-flex-a'>
                                            <Button
                                              className='download-btn mr-20'
                                              id='demo-customized-button'
                                              aria-controls={
                                                open ? 'demo-customized-menu' : undefined
                                              }
                                              aria-haspopup='true'
                                              aria-expanded={open ? 'true' : undefined}
                                              disableElevation
                                              onClick={(e) => menuOpen(e, data)}
                                              endIcon={<KeyboardArrowDownIcon />}>
                                              {' '}
                                              <img
                                                src={DownloadIcon}
                                                alt='download'
                                                className='mr-7'
                                              />{' '}
                                              Download
                                            </Button>
                                            <StyledMenu
                                              sx={{ left: '30px' }}
                                              id='demo-customized-menu'
                                              MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                              }}
                                              open={open}
                                              anchorEl={anchorEl}
                                              onClose={menuClose}>
                                              <MenuItem
                                                onClick={() => {
                                                  window.open(
                                                    encodeUriTourl(
                                                      JSON.parse(JSON.parse(row?.file_source))
                                                        .raw_pdf_path
                                                        ? JSON.parse(JSON.parse(row?.file_source))
                                                            .raw_pdf_path
                                                        : JSON.parse(JSON.parse(row?.file_source))
                                                            .attachment,
                                                    ),
                                                    '_blank',
                                                  );
                                                  menuClose();
                                                }}
                                                disableRipple>
                                                Input File
                                              </MenuItem>
                                              {JSON.parse(row?.batched_result) && (
                                                <MenuItem
                                                  onClick={() => {
                                                    window.open(uriTourl(tiffFile?.url), '_blank');
                                                    menuClose();
                                                  }}
                                                  disableRipple>
                                                  Tiff
                                                </MenuItem>
                                              )}
                                            </StyledMenu>
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Typography>
                                  </Box>
                                );
                              })
                            ) : (
                              <Box sx={{ margin: 1 }} key={index}>
                                <Typography component={'div'} className='inner-table-row d-flex-a'>
                                  <Grid container>
                                    <Grid md={3} sm={12}>
                                      <Typography className='inner-title'>Batch Name</Typography>
                                      <Typography>{'--'}</Typography>
                                    </Grid>
                                    <Grid md={3} sm={12}>
                                      <Typography className='inner-title d-flex-a'>
                                        File Name
                                      </Typography>
                                      <Typography>
                                        <TextOnlyTooltip
                                          arrow
                                          title={
                                            !_.isEmpty(clarifyTableData[index]?.file_source)
                                              ? JSON.parse(clarifyTableData[index]?.file_source)
                                                  ?.email_meta_data_path?.split('/')
                                                  ?.pop()
                                              : '--'
                                          }>
                                          <div
                                            style={{
                                              width: 300,
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                            }}>
                                            {!_.isEmpty(clarifyTableData[index]?.file_source)
                                              ? JSON.parse(clarifyTableData[index]?.file_source)
                                                  ?.email_meta_data_path?.split('/')
                                                  ?.pop()
                                              : '--'}
                                          </div>
                                        </TextOnlyTooltip>
                                      </Typography>
                                    </Grid>
                                    <Grid md={1} sm={12}>
                                      <Typography className='inner-title'>Image ID</Typography>
                                      <Typography>{'--'}</Typography>
                                    </Grid>
                                    <Grid md={4.5} sm={12} className='d-flex-a flex-end'>
                                      <Typography component={'div'} className='d-flex-a'>
                                        <Button
                                          className='download-btn mr-20'
                                          id='demo-customized-button'
                                          disableElevation
                                          onClick={() => {
                                            window.open(
                                              encodeUriTourl(
                                                JSON.parse(clarifyTableData[index]?.file_source)
                                                  .raw_pdf_path
                                                  ? JSON.parse(clarifyTableData[index]?.file_source)
                                                      .raw_pdf_path
                                                  : JSON.parse(clarifyTableData[index]?.file_source)
                                                      .attachment,
                                              ),
                                              '_blank',
                                            );
                                            menuClose();
                                          }}>
                                          <img src={DownloadIcon} alt='download' className='mr-7' />
                                          Download
                                        </Button>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Typography>
                              </Box>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      </Typography>
      {
        <>
          <Modal
            open={iclarifyModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '90vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
              }}>
              <Typography sx={{ display: 'flex', justifyContent: 'end' }}>
                <CloseIcon className='pointer' onClick={() => setIclarifyModal(false)} />
              </Typography>
              <div>
                <ImageViewer splitInfo={overallData} />
              </div>
            </Box>
          </Modal>
        </>
      }
    </Typography>
  );
};
