/* eslint-disable camelcase */
import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
import Doc from 'assets/icon/doc.svg';
import P2 from 'assets/icon/p2.svg';
import Creydown from 'assets/icon/creydown.svg';
import Batc from 'assets/icon/batch.svg';
import Pendin from 'assets/icon/pending.svg';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Header from 'components/Header';
import { Chart } from 'react-google-charts';
import CustomSelect from 'components/customSelect';
import axios from 'axios';
import { TABLE_NAME } from 'utils/constant';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import dayjs, { Dayjs } from 'dayjs';

enum analyticsReasonType {
  DISCARD = 'discard_report',
}

enum dropDownReasonType {
  DISCARDED = 'discardReport',
}

export const pieoptions = {
  title: '',
  chartArea: { width: '100%', fontName: 'Poppins-Regular' },
  is3D: false,
  pieHole: 0.4,
  colors: ['#8b0a80'],
  legend: { position: 'none' },
  // pieSliceTextStyle: {
  //   color: 'black',
  // },
};
export const lineoptions = {
  title: '',
  chartArea: { width: '100%', fontName: 'Poppins-Regular' },
  colors: ['#9dabd0'],
  legend: { position: 'none' },
};

export const baroptions = {
  title: '',
  chartArea: { width: '100%', fontName: 'Poppins-Regular' },
  colors: ['#6967f9'],
  legend: { position: 'none' },
};

export default function Analytics() {
  const reasonType = [
    {
      name: 'Completed',
      id: 'completed',
    },
    {
      name: 'Iclarify',
      id: 'iclarify',
    },
    {
      name: 'Reconcile',
      id: 'reconcile',
    },
    {
      name: 'Responded',
      id: 'responded',
    },
    {
      name: 'Discarded',
      id: 'discardReport',
    },
  ];

  const timeZone = [
    {
      label: 'EST',
      value: 'EST',
    },
    {
      label: 'GMT',
      value: 'GMT',
    },
    {
      label: 'IST',
      value: 'IST',
    },
  ];

  // const [age, setAge] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [tz, setTz] = React.useState('');
  const [loader, setLoader] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<any>('');
  const [pendingCount, setPendingCount] = React.useState<any>('');
  const [dateValue, setDateValue] = React.useState<DateRange<Dayjs>>([dayjs(null), dayjs(null)]);
  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };
  const handleEvent = (picker: any) => {
    setDateValue(picker);
  };

  const encodeUriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = encodeURIComponent(match[2]);
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/${key}`;
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    const headers = {
      Accept: 'application/json',
    };

    const fromDate =
      dateValue && dateValue[0] && dateValue[0]?.format('YYYY-MM-DD') !== 'Invalid Date'
        ? dateValue[0].format('YYYY-MM-DD')
        : '';
    const toDate =
      dateValue && dateValue[1] && dateValue[1]?.format('YYYY-MM-DD') !== 'Invalid Date'
        ? dateValue[1].format('YYYY-MM-DD')
        : '';
    if (fromDate && toDate && tz && reason === 'completed') {
      setLoader(true);
      axios
        .get(
          `https://d2lreports.itechlab.in/production?table_name=${TABLE_NAME.completed}&from_date=${fromDate}&to_date=${toDate}&time_zone=${tz} `,
          { headers },
        )
        .then((res: any) => {
          setLoader(false);
          setFile(res);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.detail || err?.message || 'Something went wrong');
        });
    } else if (fromDate && toDate && tz && reason === 'reconcile') {
      setLoader(true);

      axios
        .get(
          `https://d2lreports.itechlab.in/reconcile?from_date=${fromDate}&to_date=${toDate}&time_zone=${tz} `,
          {
            headers,
          },
        )
        .then((res: any) => {
          setLoader(false);
          setFile(res);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.detail || err?.message || 'Something went wrong');
        });
    } else if (fromDate && toDate && tz && reason === 'iclarify') {
      setLoader(true);

      axios
        .get(
          `https://d2lreports.itechlab.in/clarify?table_name=${TABLE_NAME.iclarify}&from_date=${fromDate}&to_date=${toDate}&time_zone=${tz} `,
          { headers },
        )
        .then((res: any) => {
          setLoader(false);
          setFile(res);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.detail || err?.message || 'Something went wrong');
        });
    } else if (fromDate && toDate && tz && reason === 'responded') {
      setLoader(true);

      axios
        .get(
          `https://d2lreports.itechlab.in/responded?table_name=${TABLE_NAME.iclarify}&from_date=${fromDate}&to_date=${toDate}&time_zone=${tz} `,
          { headers },
        )
        .then((res: any) => {
          setLoader(false);

          setFile(res);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.detail || err?.message || 'Something went wrong');
        });
    } else if (fromDate && toDate && tz && reason === dropDownReasonType.DISCARDED) {
      setLoader(true);
      axios
        .get(
          `https://d2lreports.itechlab.in/${analyticsReasonType.DISCARD}?from_date=${fromDate}&to_date=${toDate}&time_zone=${tz} `,
          { headers },
        )
        .then((res: any) => {
          setLoader(false);
          setFile(res);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err?.response?.data?.detail || err?.message || 'Something went wrong');
        });
    }

    // if (fromDate && toDate && reason === 'error') {
    //   setLoader(true);

    //   axios
    //     .get(
    //       `https://d2lreports.itechlab.in/error?table_name=${TABLE_NAME.error}&from_date=${fromDate}&to_date=${toDate} `,
    //       { headers },
    //     )
    //     .then((res: any) => {
    //       setLoader(false);

    //       setFile(res);
    //     })
    //     .catch((err) => {
    //       setLoader(false);
    //       console.log(err);
    //     });
    // }
  }, [dateValue, reason]);

  React.useEffect(() => {
    axios
      .get('https://d2lreports.itechlab.in/count_batch')
      .then((res) => {
        setPendingCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const clearField = () => {
    setFile('');
    setTz('');
    setReason('');
    setDateValue([dayjs(null), dayjs(null)]);
  };

  const execlExport = () => {
    if (file.data.Result !== 'No record found for the given date') {
      window.open(encodeUriTourl(file.data.Result));
      clearField();
    } else {
      toast.error('No record found for the given date');
      clearField();
    }
  };
  return (
    <Typography component={'div'}>
      <Header activeIndex={2} />
      <Typography component={'div'} className='innerbody'>
        <Loader openLoader={loader} />
        {/* <Typography component={'div'} className='d-flex-a flex-end mb-16'>
          <Box sx={{ width: 120 }} className='analy-select'>
            <FormControl fullWidth>
              <InputLabel id='report'> Report</InputLabel>
              <Select
                labelId='report'
                id='demo-simple-select'
                value={age}
                IconComponent={() => <ExpandMoreIcon className='d-arrow' />}
                onChange={handleChange}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Typography> */}
        <Typography component={'div'} className='total-box'>
          <Typography component={'div'} className='total-pare-box'>
            <Grid container columnSpacing={2}>
              <Grid item md={2.4} sm={12}>
                <Typography component={'div'} className='total-in-box'>
                  <Typography component={'div'}>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>
                        <img src={Doc} alt='doc' />
                      </Typography>
                      <Typography component={'div'} className='flex-1 pl-24'>
                        <Typography className='t-title'>0</Typography>
                        <Typography className='t-info'>Documents</Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <Typography component={'div'} className='total-in-box'>
                  <Typography component={'div'}>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography>
                        <img src={Batc} alt='Batc' />
                      </Typography>
                      <Typography component={'div'} className='flex-1 pl-24'>
                        <Typography className='t-title'>0</Typography>
                        <Typography className='t-info'>Batches</Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <Typography component={'div'} className='total-in-box'>
                  <Typography component={'div'}>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='p-relative'>
                        <img src={Pendin} alt='Pendin' />
                        <img src={P2} alt='Pendin' className='p2' />
                      </Typography>
                      <Typography component={'div'} className='flex-1 pl-24'>
                        <Typography className='t-title'>0</Typography>
                        <Typography className='t-info'>Pending</Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <Typography component={'div'} className='total-in-box'>
                  <Typography component={'div'}>
                    <Typography component={'div'} className='d-flex-a mb-8'>
                      <Typography className='t-title-16'>Open Batch(s) :</Typography>
                      <img src={Creydown} alt='down' className='ml-9' />
                    </Typography>
                    <Typography className='t-info'>Pick Batch ID</Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <Typography component={'div'} className='total-in-box'>
                  <Typography component={'div'}>
                    <Typography component={'div'} className='d-flex-a mb-8'>
                      <Typography className='t-title-16'>Close Batch(s) :</Typography>
                      <img src={Creydown} alt='down' className='ml-9' />
                    </Typography>
                    <Typography className='t-info'>Pick Batch ID</Typography>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Typography className='t-info-16'>
            Docs pending for control file : {pendingCount.pending_count}
          </Typography>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-end mb-26'>
          {file?.data?.Result && (
            <Button className='move-btn mr-7' style={{ marginTop: '7px' }} onClick={execlExport}>
              Download
            </Button>
          )}
          {/* <Box sx={{ width: 180 }} className='analy-select mr-12 mt-8'>
            <FormControl fullWidth>
              <InputLabel id='sr'>Summary Report</InputLabel>
              <Select
                labelId='sr'
                id='demo-simple-select'
                value={age}
                IconComponent={() => <ExpandMoreIcon className='d-arrow' />}
                onChange={handleChange}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
          <Box sx={{ width: 180 }} className='analy-select mr-12 mt-8 pointer'>
            <FormControl fullWidth>
              <CustomSelect
                onChange={(e: any) => {
                  setTz(e?.target?.value);
                }}
                value={tz ? tz : null}
                placeHolder='Select Timezone'
                options={timeZone}
                labelKey={'label'}
                valueKey={'value'}
              />
            </FormControl>
          </Box>
          <Box sx={{ width: 180 }} className='analy-select mr-12 mt-8'>
            <FormControl fullWidth>
              <CustomSelect
                onChange={(e: any) => {
                  setReason(e?.target?.value);
                }}
                className='pointer'
                value={reason ? reason : null}
                placeHolder='Report Type'
                options={reasonType}
                labelKey={'name'}
                valueKey={'id'}
              />
            </FormControl>
          </Box>

          <Typography component={'div'} className='custom-date' sx={{ width: '330px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangePicker']}>
                <DateRangePicker
                  className='pointer'
                  value={dateValue}
                  onChange={handleEvent}
                  localeText={{ start: 'From Date', end: 'To Date' }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Typography>
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item md={4} sm={12}>
            <Typography component={'div'} className='line-parent mb-26'>
              <Typography component={'div'} className='line-top d-flex-a'>
                <Typography component={'div'}>
                  <Typography component={'div'} className='title-18'>
                    Document Status (0)
                  </Typography>
                  {/* <Typography className='n-info'>01/01/2023 - 10/01/2023</Typography> */}
                </Typography>
              </Typography>
              <Typography component={'div'} className='line-body'>
                <Chart
                  chartType='Line'
                  data={[
                    ['', 'Weight'],
                    [4, 5.5],
                    [8, 12],
                  ]}
                  width='100%'
                  height='300px'
                  options={lineoptions}
                  legendToggle
                />
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography component={'div'} className='line-parent mb-26'>
              <Typography component={'div'} className='line-top d-flex-a'>
                <Typography component={'div'}>
                  <Typography component={'div'} className='title-18'>
                    Document Status (0)
                  </Typography>
                  {/* <Typography className='n-info'>01/01/2023 - 10/01/2023</Typography> */}
                </Typography>
              </Typography>
              <Typography component={'div'} className='line-body'>
                <Chart
                  chartType='Bar'
                  data={[
                    ['', 'Weight'],
                    [4, 5.5],
                    [8, 12],
                  ]}
                  width='100%'
                  height='300px'
                  options={baroptions}
                  legendToggle
                />
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography component={'div'} className='line-parent mb-26'>
              <Typography component={'div'} className='line-top d-flex-a'>
                <Typography component={'div'}>
                  <Typography component={'div'} className='title-18'>
                    Document Status (0)
                  </Typography>
                  {/* <Typography className='n-info'>01/01/2023 - 10/01/2023</Typography> */}
                </Typography>
              </Typography>
              <Typography component={'div'} className='line-body'>
                {/* <Chart
                  chartType='PieChart'
                  data={[
                    ['Age', 'Weight'],
                    ['4', 5.5],
                    ['8', 12],
                  ]}
                  options={pieoptions}
                  width='100%'
                  height='300px'
                  legendToggle
                /> */}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
    </Typography>
  );
}
