import * as Yup from 'yup';

export const documentFormSchema = Yup.object().shape({
  splitInfo: Yup.array().of(
    Yup.object().shape({
      isRemoveValidation: Yup.boolean().optional(),
      clientCode: Yup.string().when('isRemoveValidation', {
        is: (isRemoveValidation) => isRemoveValidation,
        then: Yup.string().required('Client Code is Required'),
      }),
      scacCode: Yup.string().when('isRemoveValidation', {
        is: (isRemoveValidation) => isRemoveValidation,
        then: Yup.string().required('Scac Code is Required'),
      }),
      invoiceType: Yup.string().optional(),
    }),
  ),
});

export const scacCodeSchema = Yup.object().shape({
  scac: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac' && reasonAction === 'insert',
    then: Yup.string().required('Scac Code is Required'),
  }),

  scacEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac&Email' && reasonAction === 'insert',
    then: Yup.string().required('Scac Code is Required'),
  }),
  deletescacEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac&Email' && reasonAction === 'inactive',
    then: Yup.string().required('Scac Code is Required'),
  }),
  clientidEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac&Email' && reasonAction === 'insert',
    then: Yup.string().nullable(),
  }),
  senderEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac&Email' && reasonAction === 'insert',
    then: Yup.string().required('Sender Email is Required'),
  }),
  carrierName: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac' && reasonAction === 'insert',
    then: Yup.string().required('Carrier Name is required'),
  }),
  carrierid: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac' && reasonAction === 'insert',
    then: Yup.string().required('Carrier Id is Required'),
  }),
  clientCode: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Client Code is Required'),
  }),
  accountNumber: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Account Number is Required'),
  }),
  clientLocation: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Client Location is Required'),
  }),
  currencyType: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Currency Type is Required'),
  }),
  selectPriority: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Priority is Required'),
  }),
  processingSiteCode: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Processing Site Code is Required'),
  }),
  selectSiteCode: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'insert',
    then: Yup.string().required('Site Code is Required'),
  }),

  deleteClient: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'clientCode' && reasonAction === 'inactive',
    then: Yup.string().required('Client Code to delete is mandatory. Please enter a value.'),
  }),
  clientEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'ClientCode&Key' && reasonAction === 'insert',
    then: Yup.string().required('Client Code is Required'),
  }),
  deleteClientEmail: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'ClientCode&Key' && reasonAction === 'inactive',
    then: Yup.string().required('Client key to delete is mandatory. Please enter a value.'),
  }),
  keyWord: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'ClientCode&Key' && reasonAction === 'insert',
    then: Yup.string().required('Keyword is Required'),
  }),
  modelTrained: Yup.string().nullable(),
  deleteScac: Yup.string().when(['reason', 'reasonAction'], {
    is: (reason, reasonAction) => reason === 'scac' && reasonAction === 'inactive',
    then: Yup.string().required('SCAC Code to delete is mandatory. Please enter a value.'),
  }),
  reason: Yup.string().nullable().required('Table is Required'),
  reasonAction: Yup.string().nullable().required('Action is Required'),
});

export const loginFormSchema = Yup.object().shape({
  userName: Yup.string().nullable().required('Username is Required'),
  password: Yup.string().nullable().required('Password is Required'),
});
